// @flow
import React from 'react';
import { LinearProgress } from '@material-ui/core';
import styled from 'styled-components/macro';
import { selectGlobal } from 'context/features/global/globalSlice';
import { useSelector } from 'react-redux';
import { useIsFetching, useIsMutating } from 'react-query';

const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 111111111111111;
`;

const Loader = () => {
    const { isLoading } = useSelector((state) => selectGlobal(state));
    const IsMutating = useIsMutating();

    return (
        (isLoading || !!IsMutating) && (
            <Wrapper>
                <LinearProgress color="secondary" />
            </Wrapper>
        )
    );
};

export default Loader;
