// @flow
import React, { memo } from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    makeStyles,
    Paper,
} from '@material-ui/core';
import _ from 'lodash';
import { StyledTableCell } from 'styles/components';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
});

type Props = {
    rowsData: Array<any>,
    headers: Array<string>,
    rowRender: Function,
};

const SaleTable = ({ rowsData, headers, rowRender, ...props }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TableContainer component={Paper}>
                <Table {...props}>
                    <TableHead>
                        <TableRow>
                            {headers.map((head) => (
                                <StyledTableCell key={`head-${head}`}>
                                    {head}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsData.map((row, index) => rowRender(row, index))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default memo<Props>(
    SaleTable,
    (prev, next) =>
        _.isEqual(prev.rowsData, next.rowsData) &&
        _.isEqual(prev.headers, next.headers) &&
        _.isEqual(prev.rowRender, next.rowRender)
);
