// @flow
import React, { useState } from 'react';
import * as text from 'constants/strings';
import ViewContentCard from 'components/Layout/ViewContentCard';

import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { useHistory } from 'react-router-dom';
import SearchBar from 'components/SearchBar';

const useStyles = makeStyles(() => ({
    row: {
        '&:hover': { cursor: 'pointer' },
    },
}));

const StatementProduct = () => {
    const [result, setResult] = useState([]);
    const history = useHistory();
    const classes = useStyles();

    const handleRowClick = (rowId: number) => {
        history.push(`/historia-poszczegolnego-modelu/${rowId}`);
    };

    return (
        <ViewContentCard title={text.STATEMENT_PRODUCT}>
            <SearchBar setResult={setResult} />
            {result.length > 0 && (
                <SaleTableWithPagination
                    headers={[
                        text.CODE,
                        text.BRAND,
                        text.COLOR,
                        text.MODEL,
                        text.NORMAL_PRICE,
                        text.PROMOTIONAL_PRICE,
                    ]}
                    rowRender={(row) => (
                        <TableRow
                            className={classes.row}
                            onClick={() => handleRowClick(row.id)}
                            hover
                            key={row.id}
                        >
                            <TableCell align="center">{row.code}</TableCell>
                            <TableCell align="center">{row.brand}</TableCell>
                            <TableCell align="center">{row.color}</TableCell>
                            <TableCell align="center">{row.type}</TableCell>
                            <TableCell align="center">
                                {row.primary_price}
                            </TableCell>
                            <TableCell align="center">
                                {row.promotional_price}
                            </TableCell>
                        </TableRow>
                    )}
                    rowsData={result}
                />
            )}
        </ViewContentCard>
    );
};

export default StatementProduct;
