// @flow
import { Button, IconButton, TableCell } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ViewContentCard from 'components/Layout/ViewContentCard';
import SaleTable from 'components/SaleTable';
import * as text from 'constants/strings';
import {
    addAlert,
    selectShops,
    setIsLoading,
} from 'context/features/global/globalSlice';
import { selectUser } from 'context/features/user/userSlice';
import { sendAuthAjax } from 'helpers/authorization';
import { findColorShopById, formatDateInHour } from 'helpers/global';
import useIsGranted from 'hooks/useIsGranted';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const AddShoes = () => {
    const user = useSelector((state) => selectUser(state));
    const shops = useSelector((state) => selectShops(state));
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const isGranted = useIsGranted();
    const hasPermissions = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
    ]);
    const isPorajShop = user.shopId === 2;

    const getExhibitions = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                hasPermissions || isPorajShop
                    ? `/exhibitions`
                    : `/exhibition/${user.shopId}`
            );
            setData(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const transfer = async (
        shopFromId,
        shopToId,
        exhibitionId,
        size,
        productId
    ) => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('put', `/exhibition/transfer`, {
                shopFromId,
                shopToId,
                exhibitionId,
                sizes: [{ quantity: 1, size }],
                productId,
            });
            getExhibitions();
            dispatch(
                addAlert({
                    content: 'Poprawnie przerzucono buty',
                    type: 'success',
                })
            );
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const remove = async (exhibitionId) => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('delete', `/exhibition/${exhibitionId}`);
            setData(data.filter((dataRow) => dataRow.id !== exhibitionId));
            dispatch(
                addAlert({
                    content: 'Poprawnie usunięto wystawe',
                    type: 'success',
                })
            );
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getExhibitions();
    }, []);

    const handleClick = (row) => {
        if (window.confirm('Czy napewno chcesz przerzucić to obuwie?')) {
            transfer(
                row.shopFromId,
                row.shopToId,
                row.id,
                row.size,
                row.productId
            );
        }
    };

    return (
        <>
            <ViewContentCard title={text.EXHIBITION}>
                {data.length > 0 && (
                    <SaleTable
                        headers={
                            hasPermissions
                                ? [
                                      text.REMOVE,
                                      text.CODE,
                                      text.BRAND,
                                      text.COLOR,
                                      text.MODEL,
                                      text.SIZE,
                                      text.FROM_SHOP,
                                      text.TO_SHOP,
                                      text.DATE,
                                      'akcja',
                                  ]
                                : [
                                      text.CODE,
                                      text.BRAND,
                                      text.COLOR,
                                      text.MODEL,
                                      text.SIZE,
                                      isPorajShop ? text.FROM_SHOP : text.SHOP,
                                      text.DATE,
                                      'akcja',
                                  ]
                        }
                        rowRender={(row) => (
                            <TableRow
                                hover
                                key={row.id}
                                style={{
                                    backgroundColor: findColorShopById(
                                        shops,
                                        row.shopToId
                                    ),
                                }}
                            >
                                {hasPermissions && (
                                    <TableCell align="center">
                                        <IconButton
                                            onClick={() => remove(row.id)}
                                        >
                                            <DeleteOutlineIcon color="error" />
                                        </IconButton>
                                    </TableCell>
                                )}
                                <TableCell align="center">
                                    {row.productCode}
                                </TableCell>
                                <TableCell align="center">
                                    {row.productBrand}
                                </TableCell>
                                <TableCell align="center">
                                    {row.productColor}
                                </TableCell>
                                <TableCell align="center">
                                    {row.productType}
                                </TableCell>
                                <TableCell align="center">{row.size}</TableCell>
                                {hasPermissions && (
                                    <TableCell align="center">
                                        {row.shopFromName}
                                    </TableCell>
                                )}
                                <TableCell align="center">
                                    {isPorajShop && !hasPermissions
                                        ? row.shopFromName
                                        : row.shopToName}
                                </TableCell>
                                <TableCell align="center">
                                    {formatDateInHour(row.createdAt)}
                                </TableCell>
                                <TableCell align="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleClick(row)}
                                        disabled={isPorajShop}
                                    >
                                        Przerzut
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                        rowsData={data}
                    />
                )}
            </ViewContentCard>
        </>
    );
};

export default AddShoes;
