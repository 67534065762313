// @flow
import React, { useState } from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import SearchBar from 'components/SearchBar';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'context/features/user/userSlice';
import ExchangeTableRow from 'components/ExchangeTableRow';
import { validateNumberValue } from 'helpers/global';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { clearSearchParams } from 'context/features/sale/saleSlice';

const ExchangeShoes = () => {
    const [result, setResult] = useState([]);
    const dispatch = useDispatch();
    const steps = ['Przyjmij obuwie', 'Wydaj obuwie'];
    const [activeStep, setActiveStep] = useState(0);
    const user = useSelector((state) => selectUser(state));
    const [exchangeId, setExchangeId] = useState(null);

    const acceptProduct = async (productId: number, size: string) => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'post',
                `/exchange/acceptance`,
                { shopId: user.shopId, productId, sizeFrom: size }
            );
            setExchangeId(response.data);
            setActiveStep(1);
            dispatch(addAlert({ content: 'Przyjęto obuwie', type: 'success' }));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const releaseProduct = async (productId: number, size: string) => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('put', `/exchange/${exchangeId}/release`, {
                productId,
                sizeTo: size,
            });
            setExchangeId(null);
            setActiveStep(0);
            dispatch(addAlert({ content: 'Wydano obuwie', type: 'success' }));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickRow = (productId: number, size: string) => {
        if (!validateNumberValue(size)) {
            dispatch(
                addAlert({ content: 'Wybierz poprawny rozmiar', type: 'error' })
            );
            return;
        }
        if (activeStep === 1) {
            releaseProduct(productId, size);
        } else {
            acceptProduct(productId, size);
        }
        dispatch(clearSearchParams());
        setResult([]);

    };

    return (
        <ViewContentCard title={text.SHOE_REPLACEMENT}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <SearchBar setResult={setResult} />
            {result.length > 0 && (
                <SaleTableWithPagination
                    headers={[
                        text.CODE,
                        text.BRAND,
                        text.COLOR,
                        text.MODEL,
                        text.NORMAL_PRICE,
                        text.PROMOTIONAL_PRICE,
                        text.SIZE,
                        '',
                    ]}
                    rowRender={(row) => (
                        <ExchangeTableRow
                            activeStep={activeStep}
                            key={row.id}
                            row={row}
                            handleClick={handleClickRow}
                        />
                    )}
                    rowsData={result}
                />
            )}
        </ViewContentCard>
    );
};

export default ExchangeShoes;
