// @flow
import React from 'react';
import { TableCell, makeStyles } from '@material-ui/core';
import 'moment/locale/pl';
import { formatNumber } from 'helpers/global';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    released: {
        backgroundColor: theme.palette.primary.light,
        color: '#fff',
    },
    accepted: {
        backgroundColor: theme.palette.secondary.light,
        color: '#fff',
    },
    mergedText: {
        textTransform: 'uppercase',
    },
}));

type Props = {
    type: 'released' | 'accepted',
    row: any,
    merged: boolean,
};

const PreviewExchangeTableRowBlock = ({ type, row, merged }: Props) => {
    const classes = useStyles();

    return merged ? (
        <TableCell
            colSpan={7}
            className={clsx(classes[type], classes.mergedText)}
            align="center"
        >
            {type === 'released' ? 'przyjęcie (zwrot)' : row.exchange_info}
        </TableCell>
    ) : (
        <>
            <TableCell align="center" className={classes[type]}>
                {row[`code_${type}`]}
            </TableCell>
            <TableCell className={classes[type]} align="center">
                {row[`brand_${type}`]}
            </TableCell>
            <TableCell align="center" className={classes[type]}>
                {row[`color_${type}`]}
            </TableCell>
            <TableCell align="center" className={classes[type]}>
                {row[`type_${type}`]}
            </TableCell>
            <TableCell align="center" className={classes[type]}>
                {formatNumber(row[`primary_price_${type}`])}
            </TableCell>
            <TableCell align="center" className={classes[type]}>
                {formatNumber(row[`promotional_price_${type}`])}
            </TableCell>
            <TableCell align="center" className={classes[type]}>
                {row[`size_${type}`]}
            </TableCell>
        </>
    );
};

export default PreviewExchangeTableRowBlock;
