// @flow
import React, {useEffect, useState} from 'react';
import * as text from 'constants/strings';
import ViewContentCard from 'components/Layout/ViewContentCard';
import { Container, TextField, makeStyles, Button, Grid, ButtonGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { sendAuthAjax } from 'helpers/authorization';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import SaleSearchBar from 'components/SaleSearchBar';
import ShoperVariantRow from 'components/ShoperVariantRow';
import ConfirmDialog from 'components/ConfirmDialog';
import { selectSaleSearch } from 'context/features/sale/saleSlice';
import { AxiosError } from "axios";

const useStyles = makeStyles(() => ({
    row: {
        '&:hover': { cursor: 'pointer' },
    },
    marginT: {
        marginTop: '1rem',
    },
    marginB: {
        marginBottom: '2rem',
    },
}));

const ShoperConfig = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const saleSearch = useSelector((state) => selectSaleSearch(state));
    const [viewType, setViewType] = useState(text.APP_SHOPER_CONFIG_VARIANTS_MAPPING);
    const [result, setResult] = useState([]);
    const [settings, setSettings] = useState({
        syncStocksEnabled: false,
        syncPricesEnabled: false,
        apiUrl: '',
        login: '',
        password: ''
    });
    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        title: 'Uwaga, czy na pewno chcesz zapisać zmiany?',
    });

    const updateResult = (rowId, shoperVariantId) => {
        const newResult = result;
        Object.keys(newResult).map((keyName) => {
            if (newResult[keyName].id === rowId) {
                newResult[keyName].shoper_variant_id = shoperVariantId;
            }
        });
         setResult(newResult);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setConfirmDialog({...confirmDialog, open: true});
    }

    const saveSettings = async () => {
        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax('post', '/shoper-settings', settings);
            dispatch(
                addAlert({
                    content: 'Zmiany zapisane',
                    type: 'success',
                })
            );

        } catch (err) {
            const error: AxiosError = err;
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({content: errorText, type: 'error'}));
        }
        dispatch(setIsLoading(false));
    }

    const getSettings = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                '/shoper-settings'
            );

            setSettings({
                ...settings,
                login: response.data.apiLogin,
                apiUrl: response.data.apiUrl,
                syncStocksEnabled: response.data.syncStocksEnabled,
                syncPricesEnabled: response.data.syncPricesEnabled
            });
        } catch (err) {
            const error: AxiosError = err;
            if (error.response.status !== 404) {
                const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
                dispatch(addAlert({content: errorText, type: 'error'}));
            }
        }
        dispatch(setIsLoading(false));
    };

    const handleConfirmCancel = () => {
        setConfirmDialog({...confirmDialog, open: false});
    };

    const handleConfirmOk = () => {
        setConfirmDialog({...confirmDialog, open: false});
        saveSettings();
    };

    const getVariants = async () => {
        dispatch(setIsLoading(true));
        const queryString = new URLSearchParams(saleSearch).toString()
        try {
            const response = await sendAuthAjax(
                'get',
                `/product-variants?${queryString}`
            );

            setResult(response.data);
        } catch (err) {
            const error: AxiosError = err;
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const onClearSearchParams = () => {
        setResult([]);
    }

    const onSearchSuccess = (searchResult) => {
        setResult(searchResult);
    }

    useEffect(() => {
        getSettings();
    }, [])

    return (
        <ViewContentCard title={text.APP_SHOPER_CONFIG}>
            <Grid container className={classes.container}>
                <ButtonGroup
                    className={classes.buttonGroup}
                    variant="contained"
                >
                    <Button
                        onClick={() => setViewType(text.APP_SHOPER_CONFIG_VARIANTS_MAPPING)}
                        color={`${
                            viewType === text.APP_SHOPER_CONFIG_VARIANTS_MAPPING ? 'secondary' : 'primary'
                            }`}
                    >
                        {text.APP_SHOPER_CONFIG_VARIANTS_MAPPING}
                    </Button>
                    <Button
                        onClick={() => {
                            setViewType(text.APP_SHOPER_CONFIG_CONNECTION);
                            }}
                        color={`${
                            viewType === text.APP_SHOPER_CONFIG_CONNECTION
                                ? 'secondary'
                                : 'primary'
                            }`}
                    >
                        {text.APP_SHOPER_CONFIG_CONNECTION}
                    </Button>
                </ButtonGroup>
            </Grid>
            {viewType === text.APP_SHOPER_CONFIG_VARIANTS_MAPPING && 
                <>
                    <SaleSearchBar 
                        setResult={setResult}
                        showShoperVariantId
                        getProducts={getVariants}
                        onClearParams={onClearSearchParams}
                        onSearchSuccess={onSearchSuccess}
                    />
                    <SaleTableWithPagination
                        headers={
                            [
                                text.CODE,
                                text.BRAND,
                                text.COLOR,
                                text.MODEL,
                                text.SIZE,
                                text.SHOPER_VARIANT_ID,
                                text.ACTION,
                            ]
                        }
                        rowRender={(row) => (
                                <ShoperVariantRow
                                    rowData={row}
                                    key={row.id}
                                    updateResult={updateResult}
                                />
                            )}
                        rowsData={result}
                    />
                </>
            }

            {viewType === text.APP_SHOPER_CONFIG_CONNECTION && 
                <>
                    <form onSubmit={handleSubmit} noValidate>
                        <Container component="main" className={classes.marginT}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={settings.syncStocksEnabled} 
                                            onChange={(e) => setSettings({
                                                ...settings, syncStocksEnabled: e.target.checked
                                            })}
                                        />
                                    }
                                    label="Włącz synchronizację stanów magazynowych"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={settings.syncPricesEnabled} 
                                            onChange={(e) => setSettings({
                                                ...settings, syncPricesEnabled: e.target.checked
                                            })}
                                        />
                                    }
                                    label="Włącz synchronizację cen i promocji"
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.marginB}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="shoper_api_url"
                                    label="Adres url API sklepu"
                                    value={settings.apiUrl}
                                    onChange={(e) => setSettings({
                                            ...settings, apiUrl: e.target.value
                                        })
                                    }
                                />
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="shoper_api_login"
                                    label="Login"
                                    value={settings.login}
                                    onChange={(e) => setSettings({
                                            ...settings, login: e.target.value
                                        })
                                    }
                                />
                                <TextField
                                    fullWidth
                                    type="password"
                                    name="shoper_api_password"
                                    label="Hasło"
                                    value={settings.password}
                                    onChange={(e) => setSettings({
                                            ...settings, password: e.target.value
                                        })
                                    }
                                />
                            </Grid>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Zapisz zmiany
                            </Button>
                        </Container>
                    </form>
                </>
            }
            <ConfirmDialog
                open={confirmDialog.open}
                title={confirmDialog.title}
                handleCancel={handleConfirmCancel}
                handleOk={handleConfirmOk}
                cancelLabel="Nie"
                okLabel="Tak"
            />
        </ViewContentCard>
    );
};

export default ShoperConfig;
