// @flow
import React, { memo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';
import { formatNumber } from 'helpers/global';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    },
    differenceDownIcon: {
        color: colors.red[600],
    },
    differenceDownValue: {
        color: colors.red[600],
        marginRight: theme.spacing(1),
    },
    differenceUpIcon: {
        color: colors.green[600],
    },
    differenceUpValue: {
        color: colors.green[600],
        marginRight: theme.spacing(1),
    },
}));

type Props = {
    className: string,
    title: string,
    value: number,
    prevValue: number,
    prefix: ?string,
};

const SummaryBlock = ({
    className,
    title,
    value,
    prevValue,
    prefix = '',
    ...rest
}: Props) => {
    const classes = useStyles();

    const getDifferentValue = () => {
        const dif = value - prevValue;
        const char = value > prevValue ? '+' : '';

        return `${char}${formatNumber(dif)}`;
    };

    const getIcon = () => {
        let icon = null;

        switch (true) {
            case value > prevValue:
                icon = <ArrowUpwardIcon className={classes.differenceUpIcon} />;
                break;
            case value < prevValue:
                icon = (
                    <ArrowDownwardIcon className={classes.differenceDownIcon} />
                );
                break;
            default:
                icon = <RemoveIcon className={classes.differenceDownIcon} />;
                break;
        }

        return icon;
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardContent>
                <Grid container justify="flex-start">
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                        >
                            {title}
                        </Typography>
                        <Typography color="textPrimary" variant="h3">
                            {value}
                            {prefix}
                        </Typography>
                    </Grid>
                </Grid>
                <Box mt={2} display="flex" alignItems="center">
                    {getIcon()}
                    <Typography
                        className={
                            prevValue < value
                                ? classes.differenceUpValue
                                : classes.differenceDownValue
                        }
                        variant="body2"
                    >
                        {getDifferentValue()}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                        Od ostatniego miesiąca
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default memo(SummaryBlock);
