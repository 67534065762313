// @flow
import React, { useState, useContext } from 'react';
import {
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    TableCell,
    TableRow,
    Button,
} from '@material-ui/core';
import * as text from 'constants/strings';
import SearchBar from 'components/SearchBar';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { AddingComplaintContext } from 'pages/Complaint';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingBottom: theme.spacing(2),
    },
    dialogTitle: {
        paddingBottom: 0,
    },
}));

const ComplaintDialog = () => {
    const {
        productsDialogOpen,
        setProductsDialogOpen,
        setProduct,
        complaintData, 
        setComplaintData
    } = useContext(AddingComplaintContext);

    const classes = useStyles();
    const [result, setResult] = useState([]);

    const handleClose = () => {
        setProductsDialogOpen(false);
    };

    const handleClickRow = (row) => {
        setProduct(row);
        setProductsDialogOpen(false);
    };

    return (
        <Dialog
            className={classes.dialogTable}
            maxWidth={false}
            open={productsDialogOpen}
            onClose={handleClose}
            style={{ maxHeight: '90vh' }}
        >
            <DialogTitle className={classes.dialogTitle}>
                Dodawanie nowego produktu
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <SearchBar setResult={setResult} />
                {result.length > 0 && (
                    <SaleTableWithPagination
                        size="small"
                        headers={[
                            text.CODE,
                            text.BRAND,
                            text.COLOR,
                            text.MODEL,
                            text.NORMAL_PRICE,
                            text.PROMOTIONAL_PRICE,
                            text.SIZE,
                            '',
                        ]}
                        rowRender={(row) => (
                            <TableRow
                                hover
                                key={row.id}
                            >
                                <TableCell align="center">{row.code}</TableCell>
                                <TableCell align="center">
                                    {row.brand}
                                </TableCell>
                                <TableCell align="center">
                                    {row.color}
                                </TableCell>
                                <TableCell align="center">{row.type}</TableCell>
                                <TableCell align="center">
                                    {row.primary_price}
                                </TableCell>
                                <TableCell align="center">
                                    {row.promotional_price}
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        placeholder="rozmiar"
                                        type="number"
                                        onChange={(e) => setComplaintData({ ...complaintData, size: e.target.value })}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleClickRow(row)}
                                    >
                                        Dodaj
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                        rowsData={result}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ComplaintDialog;
