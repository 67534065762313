// @flow
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { FormHelperText } from '@material-ui/core';
import { sendAjax } from 'helpers/global';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'context/features/global/globalSlice';
import jwt from 'jwt-decode';
import * as text from 'constants/strings';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
}));

const Login = () => {
    const classes = useStyles();
    const history = useHistory();
    const [error, setError] = useState({
        global: '',
        username: '',
        password: '',
    });
    const dispatch = useDispatch();
    const [token, setToken] = useState(
        localStorage.getItem('currentUserToken')
    );

    useEffect(() => {
        if (token) {
            if (new RegExp(`${text.ROLE_WAREHOUSE_WORKER_CODE}`).test(jwt(token).roles.join(','))) {
                history.push('/dolozenie/zakopane');
            } else if (jwt(token).shopId === null) {
                history.push('/podglad-sprzedazy');
            } else if (new RegExp(`${text.ROLE_MANAGER_CODE}`).test(jwt(token).roles.join(','))) {
                history.push('/podglad-sprzedazy');
            } else if (new RegExp(`${text.ROLE_DIRECTOR_CODE}`).test(jwt(token).roles.join(','))) {
                history.push('/znajdz-buty');                
            } else {
                history.push('/sprzedaz');
            }
        }
    }, [token]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const username: string = e.target.elements.username.value;
        const password: string = e.target.elements.password.value;

        if (username === '' || password === '') {
            setError({
                global: '',
                password:
                    password === '' ? 'Ta wartość nie może być pusta' : '',
                username:
                    username === '' ? 'Ta wartość nie może być pusta' : '',
            });
            return;
        }

        const payload = {
            username,
            password,
        };

        dispatch(setIsLoading(true));
        try {
            const response = await sendAjax('post', '/login-check', payload);
            localStorage.setItem('currentUserToken', response.data.token);
            setToken(response.data.token);
        } catch (err) {
            setError({
                global: err.response.data.message
                    ? err.response.data.message
                    : err.response.statusText,
                username: '',
                password: '',
            });
        }
        dispatch(setIsLoading(false));
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Logowanie
                </Typography>
                <form
                    onSubmit={handleSubmit}
                    className={classes.form}
                    noValidate
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Nazwa użytkownika"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        error={!!error.username}
                        helperText={error.username}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Hasło"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        error={!!error.password}
                        helperText={error.password}
                    />
                    {!!error.global && (
                        <FormHelperText error>{error.global}</FormHelperText>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Zaloguj
                    </Button>
                </form>
            </div>
        </Container>
    );
};

export default Login;
