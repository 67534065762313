// @flow
import React, { memo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import _ from 'lodash';
import { StyledTableCell } from 'styles/components';
import { TableHead } from '@material-ui/core';
import Theme from "../styles/theme";

const useStylesTable = makeStyles({
    table: {
        minWidth: 500,
    },
});

type Props = {
    rowsData: Array<string>,
    headers: Array<string>,
    rowRender: (row: string, index: number) => React.Node,
    rowsPerPageNum: number,
    pageController: {page: number, setPage: Function}
};

const useStylesPagination = makeStyles((theme: Theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const TablePaginationActions = (props) => {
    const classes = useStylesPagination();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
};

const SaleTableWithPagination = ({
    rowsData,
    headers,
    rowRender,
    rowsPerPageNum,
    pageController,
    ...props
}: Props) => {
    const classes = useStylesTable();
    const [page, setPage] = React.useState(0);
    const rowsPerPage = rowsPerPageNum ?? 20;

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => {
        if (pageController?.setPage) {
           pageController.setPage(newPage);
        } else {
            setPage(newPage);
        }
    };

    const resultPage = pageController?.page ?? page;

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} {...props}>
                <TableHead>
                    <TableRow>
                        {headers.map((head) => (
                            <StyledTableCell key={`head-${head}`}>
                                {head}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? rowsData.slice(
                            resultPage * rowsPerPage,
                            resultPage * rowsPerPage + rowsPerPage
                          )
                        : rowsData
                    ).map((row, index) => rowRender(row, index))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            labelDisplayedRows={({ from, to, count }) =>
                                `${from}-${to} z ${count}`
                            }
                            colSpan={headers.length}
                            count={rowsData.length}
                            rowsPerPage={rowsPerPage}
                            page={resultPage}
                            onChangePage={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default memo<Props>(
    SaleTableWithPagination,
    (prev, next) =>
        _.isEqual(prev.rowsData, next.rowsData) &&
        _.isEqual(prev.headers, next.headers) &&
        _.isEqual(prev.rowRender, next.rowRender)
);
