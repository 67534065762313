// @flow
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Alerts from 'components/Alerts';
import TopFiveModels from 'components/TopFiveModels';
import SnackBarAlert from 'components/SnackBarAlert';
import { sendAjax } from 'helpers/global';
import {
    setCurrencyRate,
    addAlert 
} from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import useIsGranted from 'hooks/useIsGranted';
import * as text from 'constants/strings';
import { sendAuthAjax } from 'helpers/authorization';
import { currencyCodeWithoutPLN } from 'constants/global';
import Navigator from './Navigator';
import Header from './Header';

const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    infoArea: {
        display: 'flex',
        gap: '1rem',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    main: {
        padding: theme.spacing(2, 2),
        background: '#eaeff1',
    },
    footer: {
        padding: theme.spacing(2),
        background: '#eaeff1',
    },
}));

type Props = { children: Node };

const Base = ({ children }: Props) => {
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false);
    const dispatch = useDispatch();
    const isGranted = useIsGranted();
    const isNotMagazineWorker = !isGranted.hasOneOfRoles.execute([
        text.ROLE_WAREHOUSE_WORKER_CODE,
    ]);
    const currencies = Object.values(currencyCodeWithoutPLN);
    const currencyValues = {};

    const handleDrawerToggle = useCallback(() => {
        setMobileOpen(!mobileOpen);
    }, [mobileOpen]);

    const getCurrenciesRate = async () => {
        await Promise.all(currencies.map(async (currency) => {
            try {
                const response = await sendAjax(
                    'get',
                    `https://api.nbp.pl/api/exchangerates/rates/a/${currency}`,
                    null,
                    true
                );

                const currencyMid: number = response.data.rates[0].mid;

                currencyValues[currency] = currencyMid;
            } catch (error) {
                dispatch(
                    addAlert({
                        content: 'Wystąpił problem z pobraniem kursu '.currency,
                        type: 'error',
                    })
                );
            }
        }));
        dispatch(setCurrencyRate(currencyValues));
    };

    const setAutoShortages = async () => {
        const actualWeek = moment().format('w');
        const autoShortagesWeek = localStorage.getItem('autoShortagesWeek');

        if (autoShortagesWeek === null || autoShortagesWeek !== actualWeek) {
            try {
                await sendAuthAjax('post', `/generate-shortages`);
                localStorage.setItem('autoShortagesWeek', actualWeek);
            } catch (error) {
                const errorText = error.response.data.message
                    ? error.response.data.message
                    : error.response.statusText;
                dispatch(addAlert({ content: errorText, type: 'error' }));
            }
        }
    };

    useEffect(() => {
        setAutoShortages();
        getCurrenciesRate();
    }, []);

    return (
        <div className={classes.root}>
            <nav className={classes.drawer}>
                <Navigator
                    PaperProps={{ style: { width: drawerWidth } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                />
            </nav>
            <div className={classes.app}>
                <Header onDrawerToggle={handleDrawerToggle} />
                <main className={classes.main}>
                    <Grid container direction="column">
                        <div className={classes.infoArea}>
                            <Alerts />
                            {isNotMagazineWorker && <TopFiveModels />}
                        </div>
                        {children}
                    </Grid>
                </main>
                <SnackBarAlert />
            </div>
        </div>
    );
};

export default Base;
