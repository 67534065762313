// @flow
import React, { useContext } from 'react';
import {
    makeStyles,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';

import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { complaintStatus, kindOfNotification } from 'constants/global';
import * as text from 'constants/strings';
import { useSelector } from 'react-redux';
import { selectShopsWithoutWarehouse } from 'context/features/global/globalSlice';
import { selectUser } from 'context/features/user/userSlice';
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import { AddingComplaintContext } from 'pages/Complaint';
import type { Shop } from "../helpers/interface";
import { filterShopsWithoutEcommerce } from "../helpers/global";

const useStyles = makeStyles(() => ({
    dialogTitle: {
        paddingBottom: 0,
    },
    featured: {
        color: green.A400,
    },
}));

const ComplaintDataBlock = () => {
    const { complaintData, setComplaintData, isNew } = useContext(
        AddingComplaintContext
    );
    const classes = useStyles();
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));
    const user = useSelector((state) => selectUser(state));

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={4}>
                    <TextField
                        size="small"
                        label="Nr. paragonu"
                        variant="outlined"
                        fullWidth
                        value={complaintData.bill_number}
                        onChange={(e) =>
                            setComplaintData({
                                ...complaintData,
                                bill_number: e.target.value,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TextField
                        size="small"
                        label="Imię"
                        variant="outlined"
                        fullWidth
                        value={complaintData.first_name}
                        onChange={(e) =>
                            setComplaintData({
                                ...complaintData,
                                first_name: e.target.value,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TextField
                        size="small"
                        label="Nazwisko"
                        variant="outlined"
                        fullWidth
                        value={complaintData.last_name}
                        onChange={(e) =>
                            setComplaintData({
                                ...complaintData,
                                last_name: e.target.value,
                            })
                        }
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <TextField
                        size="small"
                        label="Miejscowość"
                        variant="outlined"
                        fullWidth
                        value={complaintData.city}
                        onChange={(e) =>
                            setComplaintData({
                                ...complaintData,
                                city: e.target.value,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <TextField
                        size="small"
                        label="Ulica"
                        variant="outlined"
                        fullWidth
                        value={complaintData.street}
                        onChange={(e) =>
                            setComplaintData({
                                ...complaintData,
                                street: e.target.value,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <TextField
                        size="small"
                        label="Kod pocztowy"
                        variant="outlined"
                        fullWidth
                        value={complaintData.post_code}
                        onChange={(e) =>
                            setComplaintData({
                                ...complaintData,
                                post_code: e.target.value,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <TextField
                        size="small"
                        label="Telefon"
                        variant="outlined"
                        fullWidth
                        value={complaintData.phone_number}
                        onChange={(e) =>
                            setComplaintData({
                                ...complaintData,
                                phone_number: e.target.value,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField
                        size="small"
                        label="Opis usterki"
                        variant="outlined"
                        fullWidth
                        value={complaintData.description}
                        onChange={(e) =>
                            setComplaintData({
                                ...complaintData,
                                description: e.target.value,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField
                        size="small"
                        label="Uwagi sprzedawcy"
                        variant="outlined"
                        fullWidth
                        value={complaintData.seller_notes}
                        onChange={(e) =>
                            setComplaintData({
                                ...complaintData,
                                seller_notes: e.target.value,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField
                        size="small"
                        label="Żądanie klienta"
                        variant="outlined"
                        fullWidth
                        value={complaintData.customer_request}
                        onChange={(e) =>
                            setComplaintData({
                                ...complaintData,
                                customer_request: e.target.value,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <KeyboardDatePicker
                        size="small"
                        clearLabel="Wyczyść"
                        cancelLabel="Anuluj"
                        inputVariant="outlined"
                        fullWidth
                        variant="dialog"
                        format="YYYY-MM-DD"
                        value={complaintData.purchase_date}
                        onChange={(date) =>
                            setComplaintData({
                                ...complaintData,
                                purchase_date: date,
                            })
                        }
                        label="Data zakupu"
                        clearable
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="status">{text.STATUS}</InputLabel>
                        <Select
                            disabled={isNew}
                            labelId="status"
                            value={complaintData.status}
                            onChange={(e) =>
                                setComplaintData({
                                    ...complaintData,
                                    status: e.target.value,
                                })
                            }
                            label={text.STATUS}
                        >
                            {Object.entries(complaintStatus).map(
                                ([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        {value}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl size="small" variant="outlined" fullWidth>
                        <InputLabel id="shopFrom">{text.FROM_SHOP}</InputLabel>
                        <Select
                            labelId="shopFrom"
                            value={complaintData.shop_id}
                            onChange={(e) =>
                                setComplaintData({
                                    ...complaintData,
                                    shop_id: e.target.value,
                                })
                            }
                            label={text.FROM_SHOP}
                            className={clsx(
                                complaintData.shopId === user.shopId &&
                                    classes.featured,
                                classes.shopsSelect
                            )}
                        >
                            {filterShopsWithoutEcommerce(shops, (shop: Shop) => (
                                <MenuItem
                                    className={clsx(
                                        user.shopId === shop.id &&
                                        classes.featured
                                    )}
                                    key={shop.id}
                                    value={shop.id}
                                >
                                    {shop.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl size="small" variant="outlined" fullWidth>
                        <InputLabel id="shopFrom">
                            Rodzaj powiadomienia
                        </InputLabel>
                        <Select
                            labelId="shopFrom"
                            value={complaintData.notification_type}
                            onChange={(e) =>
                                setComplaintData({
                                    ...complaintData,
                                    notification_type: e.target.value,
                                })
                            }
                            label="Rodzaj powiadomienia"
                        >
                            {Object.entries(kindOfNotification).map(
                                ([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        {value}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

export default ComplaintDataBlock;
