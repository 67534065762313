// @flow
import React from 'react';
import { useLocation } from 'react-router-dom';

const NoMatch = () => {
    const location = useLocation();

    return (
        <div>
            <h3>
                Nie znaleziono strony
                <code>{location.pathname}</code>
            </h3>
        </div>
    );
};

export default NoMatch;
