// @flow
import React, { useState, useEffect } from 'react';
import {
    makeStyles,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    TableRow,
    TableCell,
    IconButton,
} from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import { useDispatch } from 'react-redux';
import 'moment/locale/pl';
import SaleTable from 'components/SaleTable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DictionaryDialog from 'components/DictionaryDialog';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: `${theme.spacing(1)}px 0px`,
    },
}));

const Dictionaries = () => {
    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState('');
    const [result, setResult] = useState([]);
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [id, setId] = useState(null);

    const handleClose = () => {
        setDialogOpen(false);
        setId(null);
    };

    const handleOpen = (e, rowId = null) => {
        setId(rowId);
        setDialogOpen(true);
    };

    const getCategories = async () => {
        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthAjax(
                'get',
                `/dictionaries/categories`
            );

            setCategories(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const getResultPerCategory = async () => {
        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthAjax(
                'get',
                `/dictionaries/categories/${category}`
            );

            setResult(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickRemove = async (rowId) => {
        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax('delete', `/dictionaries/${rowId}`);
            dispatch(
                addAlert({ content: 'Poprawnie usunięto', type: 'success' })
            );
            setResult(result.filter((item) => item.id !== rowId));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        if (categories.length > 0 && category && !dialogOpen) {
            getResultPerCategory();
        }
    }, [category, categories, dialogOpen]);

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <ViewContentCard title={text.DICTIONARIES}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} md={6} lg={2}>
                    <FormControl
                        className={classes.formControl}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                    >
                        <InputLabel shrink id="content-select-label">
                            Kategoria słownika
                        </InputLabel>
                        <Select
                            labelId="content-select-label"
                            value={category}
                            label="Kategoria słownika"
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            {categories.map((c) => (
                                <MenuItem
                                    key={c.category_name}
                                    value={c.category_name}
                                >
                                    {text.dictionary[c.category_name]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {category && (
                    <Grid item xs={12} md={6} lg={2}>
                        <Button
                            fullWidth
                            onClick={handleOpen}
                            variant="contained"
                            color="primary"
                        >
                            Dodaj wartość do słownika
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {result.length > 0 && (
                        <SaleTable
                            headers={[text.REMOVE, 'wartość', '']}
                            rowRender={(row) => (
                                <TableRow hover key={row.id}>
                                    <TableCell
                                        align="center"
                                        style={{ width: '10%' }}
                                    >
                                        <IconButton
                                            onClick={() =>
                                                handleClickRemove(row.id)
                                            }
                                        >
                                            <DeleteOutlineIcon color="error" />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.value}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={{ width: '10%' }}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) =>
                                                handleOpen(e, row.id)
                                            }
                                        >
                                            Edytuj
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )}
                            rowsData={result}
                        />
                    )}
                </Grid>
            </Grid>
            <DictionaryDialog
                open={dialogOpen}
                handleClose={handleClose}
                id={id}
                category={category}
            />
        </ViewContentCard>
    );
};

export default Dictionaries;
