// @flow
import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Typography
} from '@material-ui/core';
import { sendAuthAjax } from 'helpers/authorization';
import {
    setIsLoading,
    selectShops,
    addAlert,
} from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { StyledTableCell } from 'styles/components';
import red from '@material-ui/core/colors/red';
import { selectUser } from 'context/features/user/userSlice';
import { Shop } from 'helpers/interface';
import AddInventoryBlock from './AddInventoryBlock';
import { WAREHOUSE } from 'constants/strings';

const CustomTableCell = styled(StyledTableCell)`
    color: ${({ fontcolor }) => fontcolor};
    background-color: ${({ bgcolor }) => bgcolor && bgcolor};
`;

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 'auto',
        overflow: 'hidden',
        width: '100%',
        marginBottom: '20px',
    },
    bar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    contentWrapper: {
        margin: '10px 16px',
    },
    alert: {
        margin: '10px 0px',
    },
    root: {
        marginTop: theme.spacing(2),
        position: 'relative',
    },
    stickyCol: {
        borderRight: 'solid 1px #DDEFEF',
        left: '0',
        position: 'absolute',
        top: 'auto',
        width: '100px',
    },
    wrapper: {
        marginLeft: '100px',
        overflowX: 'auto',
    },
    h6: {
        width: '100%',
        textAlign: 'center',
        marginTop: '1.5rem',
    },
    smallTable: {
        [theme.breakpoints.up('sm')] : {
            width: '30%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
}));

type Props = { shoeId: number, title: string };

const FindShoeBlock = ({ shoeId, title }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const shops = useSelector((state) => selectShops(state));
    const user = useSelector((state) => selectUser(state));
    const [result, setResult] = useState([]);
    const [warehouseUpdate, setWarehouseUpdate] = useState(0);

    const getShoe = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/products/${shoeId}/inventories`
            );
            setResult(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getShoe();
    }, [shoeId, warehouseUpdate]);

    const getInventoryCell = (shopId: number, size: number, color: string) => {
        const sizeResult = result[size];

        let quantity = 0;
        const shopData = sizeResult.find((item) => item.shopId === shopId);

        if (shopData !== undefined) {
            quantity = shopData.quantity;
        }

        return (
            <CustomTableCell
                key={`${size}-${shopId}`}
                align="center"
                bgcolor={color}
            >
                {quantity}
            </CustomTableCell>
        );
    };

    const getSumRow = (size: number): number => {
        const data = result[size];

        const sum = data.reduce((p, c) => {
            return p + c.quantity;
        }, 0);

        return sum;
    };

    return (
        Object.keys(result).length > 0 && (
            <div
                className={
                    user.roles.includes('ROLE_WAREHOUSE_WORKER')
                        ? classes.smallTable
                        : ''
                }
            >
                <Typography variant="h6" className={classes.h6}>
                    {title}
                </Typography>
                <TableContainer className={classes.root} component={Paper}>
                    <div className={classes.wrapper}>
                        <Table
                            size="small"
                            className={classes.table}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell
                                        align="center"
                                        className={classes.stickyCol}
                                    >
                                        Rozmiar
                                    </CustomTableCell>
                                    {!user.roles.includes(
                                        'ROLE_WAREHOUSE_WORKER'
                                    ) && (
                                        <CustomTableCell
                                            bgcolor={red.A400}
                                            align="center"
                                        >
                                            Suma
                                        </CustomTableCell>
                                    )}

                                    {shops.map((shop: Shop) =>
                                        shop.short !== 'si' &&
                                        (!user.roles.includes('ROLE_WAREHOUSE_WORKER') || shop.name === WAREHOUSE) ? (
                                            <CustomTableCell
                                                bgcolor={shop.color}
                                                key={shop.id}
                                                align="center"
                                            >
                                                {shop.name}
                                            </CustomTableCell>
                                        ) : null
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(result).map(
                                    (key) =>
                                        getSumRow(key) > 0 && (
                                            <TableRow key={key}>
                                                <TableCell
                                                    className={
                                                        classes.stickyCol
                                                    }
                                                    align="center"
                                                >
                                                    {key}
                                                </TableCell>
                                                {!user.roles.includes(
                                                    'ROLE_WAREHOUSE_WORKER'
                                                ) && (
                                                    <TableCell
                                                        align="center"
                                                        bgcolor={red.A400}
                                                    >
                                                        {getSumRow(key)}
                                                    </TableCell>
                                                )}

                                                {shops.map((shop: Shop) =>
                                                    shop.short !== 'si' &&
                                                    (!user.roles.includes('ROLE_WAREHOUSE_WORKER') || shop.name === WAREHOUSE) ? 
                                                        getInventoryCell(
                                                              shop.id,
                                                              key,
                                                              shop.color
                                                          )
                                                        : null
                                                )}
                                            </TableRow>
                                        )
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
                {/ROLE_EMPLOYEE|ROLE_DIRECTOR/.test(user.roles.join(',')) && (
                    <AddInventoryBlock
                        warehouseUpdate={warehouseUpdate}
                        setWarehouseUpdate={setWarehouseUpdate}
                        shopId={user.shopId}
                        warehouseId={
                            shops.find((shop: Shop) => shop.name === 'Magazyn')
                                .id
                        }
                        productId={shoeId}
                    />
                )}
            </div>
        )
    );
};

export default FindShoeBlock;
