// @flow
import React from 'react';
import {
    Checkbox,
    Grid,
    Select,
    TextField,
    makeStyles,
    MenuItem,
    FormControl,
    FormHelperText,
} from '@material-ui/core';
import { ChecklistItem as ChecklistItemInterface, ChecklistItemOption as ChecklistItemOptionInterface } from 'helpers/interface';
import { Control, Controller, useWatch } from 'react-hook-form';
import { Modes } from 'pages/ChecklistCreate';
import { isEmpty, map, sortBy } from 'lodash';

const useStyles = makeStyles(() => ({
    checklistItemName: {
        height: '100%',
    },
    centerItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    divider: {
        margin: '10px 0',
    },
}));

type Props = {
    categoryId: number;
    item: ChecklistItemInterface;
    control: Control;
    mode: Modes;
};

const ChecklistCardItem = ({
    categoryId,
    item,
    control,
    mode
}: Props) => {
    const getSelectBoxDefaultValue = () => {
        if (mode === Modes.add || item.selected_option_id === undefined) {
            return !isEmpty(item.checklist_item_options) ? sortBy(item.checklist_item_options, ['position'])[0].id : null
        }

        return item.selected_option_id;
    }

    const getCheckboxDefaultValue = () => {
        if (mode === Modes.add) {
            return false;
        }

        return item.checked
    }

    const getCommentDefaultValue = () => {
        if (mode === Modes.add || item.comment === undefined) {
            return '';
        }

        return item.comment;
    }

    const classes = useStyles();

    const selectName = `select-${categoryId}-${item.id}`;
    const checkboxName = `checkbox-${categoryId}-${item.id}`;

    const watch = useWatch({
        control,
        name: [selectName, checkboxName ]
    });

    const shouldBeTextFieldDisabled = () => {
        let selectValue = watch[0];
        let checkboxValue = watch[1];

        if (selectValue === undefined) {
            selectValue = getSelectBoxDefaultValue();
        }

        if (checkboxValue === undefined) {
            checkboxValue = getCheckboxDefaultValue();
        }
        
        if (checkboxValue) {
            return true;
        }

        const option = item.checklist_item_options.find(
            (element: ChecklistItemOptionInterface) => element.id === selectValue
        );

        if (!option) {
            return false;
        }

        return !option.needs_improvement;
    };

    return (
        <>
            <Grid container spacing={1} justify="flex-start">
                <Grid item xs={12} md={4}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.checklistItemName}
                    >
                        {item.name}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={2} className={classes.centerItems}>
                    {item.has_custom_options ? (
                        <Controller
                            name={`select-${categoryId}-${item.id}`}
                            control={control}
                            defaultValue={getSelectBoxDefaultValue()}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl
                                    fullWidth
                                    error={!!error?.message}
                                >
                                    <Select
                                        {...field}
                                    >
                                        {map(sortBy(item.checklist_item_options, ['position']), (option: ChecklistItemOptionInterface) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {error?.message && (
                                        <FormHelperText
                                            error={!!error?.message}
                                        >
                                            {error?.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                    ) : (
                        <Controller
                            name={`checkbox-${categoryId}-${item.id}`}
                            control={control}
                            defaultValue={getCheckboxDefaultValue()}
                            render={({ field, fieldState: { error } }) => (
                                <Checkbox
                                    color="primary"
                                    defaultChecked={getCheckboxDefaultValue()}
                                    {...field}
                                />
                            )}
                        />
                    )}
                </Grid>

                <Grid item xs={12} md={6}>
                    <Controller
                        rules={{
                            maxLength: {
                                value: 255,
                                message:
                                    'Maksymalna długość wynosi 255 znaków',
                            },
                        }}
                        name={`comment-${categoryId}-${item.id}`}
                        control={control}
                        defaultValue={getCommentDefaultValue()}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                size="small"
                                helperText={error?.message}
                                error={!!error?.message}
                                label="Komentarz"
                                variant="outlined"
                                fullWidth
                                disabled={shouldBeTextFieldDisabled()}
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default ChecklistCardItem;
