import { AxiosError } from 'axios';
import { sendAuthAjax } from 'helpers/authorization';
import { AttendanceDto } from 'helpers/interface';
import { useMutation, UseMutationOptions } from 'react-query';

const path = '/attendance';

const putAttendance = async (payload: AttendanceDto) => {
    const { data } = await sendAuthAjax('put', path, payload);
    return data;
};

export default function useUpdateAttendanceMutation(
    options: UseMutationOptions<void, AxiosError, AttendanceDto> = {}
) {
    return useMutation<void, AxiosError, AttendanceDto>(putAttendance, options);
}
