// @flow
import React, { useContext, useState, useEffect } from 'react';
import {
    TextField,
    Button,
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import { AddingInvoiceContext } from 'pages/Invoice';
import { sendAuthAjax } from 'helpers/authorization';
import { trimStringAndReturnNullIfNoLength } from 'helpers/global';

const useStyles = makeStyles(() => ({
    dialogTitle: {
        paddingBottom: 0,
    },
}));

const EditCustomerDialog = () => {
    const {
        getCustomers,
        editCustomerDialogOpen,
        setEditCustomerDialogOpen,
        selectCustomer,
        setSelectCustomer,
        customers,
    } = useContext(AddingInvoiceContext);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [customerToEdit, setCustomerToEdit] = useState(null);

    useEffect(() => {
        if (editCustomerDialogOpen == false) return; 
        setCustomerToEdit(customers.find(element => element.id == selectCustomer.id))
    }, [editCustomerDialogOpen]);

    const handleClose = () => {
        setEditCustomerDialogOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const id = customerToEdit.id;
        const nip = e.target.elements.nip.value;
        const companyName = e.target.elements.companyName.value;
        const street = e.target.elements.street.value;
        const postCode = e.target.elements.postCode.value;
        const city = e.target.elements.city.value;
        const personName = e.target.elements.personName.value;

        const payload = {
            id,
            nip,
            companyName,
            street,
            postCode,
            city,
            personName,
        };

        for (let item in payload) {
            payload[item] = trimStringAndReturnNullIfNoLength(payload[item].toString());
        }

        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('put', `/customer`, payload);

            getCustomers();
            setSelectCustomer(response.data);
            setEditCustomerDialogOpen(false);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    return (
        <Dialog open={editCustomerDialogOpen} onClose={handleClose}>
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
                <DialogTitle className={classes.dialogTitle}>
                    Edytowanie nabywcy o numerze ID : {customerToEdit != null ? customerToEdit.id : null}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="NIP"
                        name="nip"
                        defaultValue={customerToEdit != null ? customerToEdit.nip : null}
                        autoComplete='off'
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Nazwa firmy"
                        name="companyName"
                        defaultValue={customerToEdit != null ? customerToEdit.companyName : null}
                        autoComplete='off'
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Osoba"
                        name="personName"
                        defaultValue={customerToEdit != null ? customerToEdit.personName : null}
                        autoComplete='off'
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Miejscowość"
                        name="city"
                        defaultValue={customerToEdit != null ? customerToEdit.city : null}
                        autoComplete='off'
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Ulica"
                        name="street"
                        defaultValue={customerToEdit != null ? customerToEdit.street : null}
                        autoComplete='off'
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Kod pocztowy"
                        name="postCode"
                        defaultValue={customerToEdit != null ? customerToEdit.postCode : null}
                        autoComplete='off'
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Anuluj
                    </Button>
                    <Button type="submit" color="primary">
                        Zapisz
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EditCustomerDialog;
