import React, { useContext, useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    DialogActions,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
} from '@material-ui/core';
import { Add, Delete, Remove } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import moment from 'moment';
import { AttendanceScheduleContext } from 'pages/GraphicAttendanceSchedule';
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as text from 'constants/strings';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingBottom: theme.spacing(2),
    },
    dialogTitle: {
        paddingBottom: 0,
    },
    quantityContent: {
        paddingTop: '16px',
        marginBottom: '-8px',
    },
}));

const GraphicAttendanceScheduleDialog = () => {
    const {
        startDate,
        scheduleDialogOpen,
        handleScheduleDialogClose,
        setUpdate,
        shopScheduleSheet,
        selectDate,
        selectShop,
    } = useContext(AttendanceScheduleContext);

    const dispatch = useDispatch();
    const classes = useStyles();
    const [data, setData] = useState({
        shop: null,
        quantity: 1,
        shopScheduleSheet: null,
        dateFrom: null,
        dateTo: null,
    });

    const handleClickSave = async () => {
        const payload = {
            ...data,
            dateFrom: moment(data.dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(data.dateTo).format('YYYY-MM-DD'),
        };

        if (data.dateTo < data.dateFrom) {
            dispatch(
                addAlert({
                    content: 'Data "do" nie może być mniejsza od daty "od"',
                    type: 'error',
                })
            );
            return;
        }

        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('post', '/attendance-schedule', payload);

            dispatch(
                addAlert({ content: 'Poprawnie zapisano', type: 'success' })
            );

            handleScheduleDialogClose();
            setUpdate(Date.now());
        } catch (error) {
            const errorText =
                error.response.data.message || error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const handleQuantityChange = (amount) => {
        setData((prevData) => ({
            ...prevData,
            quantity: Math.max(0, prevData.quantity + amount),
        }));
    };

    const deleteShopScheduleSheet = async (id) => {
        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax('delete', `/attendance-schedule/${id}`);

            dispatch(
                addAlert({ content: 'Poprawnie usunięto', type: 'success' })
            );

            handleScheduleDialogClose();
            setUpdate(Date.now());
        } catch (error) {
            const errorText =
                error.response.data.message || error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    useEffect(() => {
        setData({
            ...data,
            dateFrom: selectDate || moment(startDate),
            dateTo: selectDate || moment(startDate).endOf('week'),
            shop: selectShop ?? shopScheduleSheet?.shopId,
            shopScheduleSheet: shopScheduleSheet
                ? shopScheduleSheet.scheduleId
                : null,
            quantity: shopScheduleSheet?.scheduledEmployeeQuantity ?? 1,
        });
    }, [selectShop, selectDate, shopScheduleSheet, startDate]);

    return (
        <Dialog
            maxWidth={false}
            open={scheduleDialogOpen}
            onClose={handleScheduleDialogClose}
            style={{ maxHeight: '90vh' }}
        >
            <DialogTitle className={classes.dialogTitle}>
                {text.SET_ATTENDANCE_SCHEDULE}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs={12} lg={3}>
                        <KeyboardDatePicker
                            disabled={!!selectDate}
                            label="Data od"
                            fullWidth
                            variant="inline"
                            format="YYYY-MM-DD"
                            margin="normal"
                            value={data.dateFrom}
                            onChange={(dateFrom) =>
                                setData({ ...data, dateFrom })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <KeyboardDatePicker
                            disabled={!!selectDate}
                            label="Data do"
                            fullWidth
                            variant="inline"
                            format="YYYY-MM-DD"
                            margin="normal"
                            value={data.dateTo}
                            onChange={(dateTo) => setData({ ...data, dateTo })}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel shrink>Ilość osób</InputLabel>
                            <Grid
                                container
                                alignItems="center"
                                className={classes.quantityContent}
                            >
                                <IconButton
                                    onClick={() => handleQuantityChange(-1)}
                                >
                                    <Remove />
                                </IconButton>
                                <span>{data.quantity}</span>
                                <IconButton
                                    onClick={() => handleQuantityChange(1)}
                                >
                                    <Add />
                                </IconButton>
                            </Grid>
                        </FormControl>
                    </Grid>
                    {data?.shopScheduleSheet && (
                        <Grid item xs={6} lg={3}>
                            <IconButton
                                onClick={() =>
                                    deleteShopScheduleSheet(
                                        data.shopScheduleSheet
                                    )
                                }
                            >
                                <Delete />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleScheduleDialogClose} color="secondary">
                    Anuluj
                </Button>
                <Button onClick={handleClickSave} color="primary">
                    Zapisz
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GraphicAttendanceScheduleDialog;
