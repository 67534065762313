// @flow
import React, { useState, useContext, useEffect } from 'react';
import {
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import * as text from 'constants/strings';
import SearchBar from 'components/SearchBar';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { ProductOfTheDayContext } from 'pages/ProductOfTheDay';
import { productOfTheDayDialogModes } from 'constants/global';
import ProductOfTheDayDialogTableRow from './ProductOfTheDayDialogTableRow';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingBottom: theme.spacing(2),
    },
    dialogTitle: {
        paddingBottom: 0,
    },
}));

const ProductOfTheDayDialog = () => {
    const { dialogConfig, setDialogConfig, refreshData} = useContext(
        ProductOfTheDayContext
    );
    const classes = useStyles();
    const [result, setResult] = useState([]);

    const handleClose = () => {
        setDialogConfig({...dialogConfig, visible: false})
    };

    useEffect(() => {
        setResult(dialogConfig.defaultResult);
    }, [dialogConfig]);

    return (
        <Dialog
            maxWidth={false}
            open={dialogConfig.visible}
            onClose={handleClose}
            style={{ maxHeight: '90vh' }}
        >
            <DialogTitle className={classes.dialogTitle}>
                { dialogConfig.mode === productOfTheDayDialogModes.add && 'Dodawanie nowego produktu dnia'}
                { dialogConfig.mode === productOfTheDayDialogModes.edit && 'Edycja produktu dnia'}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                { dialogConfig.mode === productOfTheDayDialogModes.add && (<SearchBar setResult={setResult} />)}
                {result.length > 0 && (
                    <SaleTableWithPagination
                        size="small"
                        headers={[
                            text.CODE,
                            text.BRAND,
                            text.COLOR,
                            text.MODEL,
                            text.NORMAL_PRICE,
                            text.PROMOTIONAL_PRICE,
                            text.PRODUCT_OF_THE_DAY_PROMOTIONAL_PRICE,
                            text.DATE_FROM,
                            text.DATE_TO,
                            '',
                        ]}
                        rowRender={(row) => (
                            <ProductOfTheDayDialogTableRow
                                rowData={row}
                                key={row.id}
                                refreshData={refreshData}
                                mode={dialogConfig.mode}
                            />
                        )}
                        rowsData={result}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ProductOfTheDayDialog;
