// @flow
import React, { useState, useEffect } from 'react';
import {
    Button,
    makeStyles,
    TableRow,
    TableCell,
    Typography,
    Grid,
} from '@material-ui/core';
import * as text from 'constants/strings';
import ViewContentCard from 'components/Layout/ViewContentCard';
import { useParams } from 'react-router-dom';
import SearchBar from 'components/SearchBar';
import FormAddSizes from 'components/FormAddSizes';
import { useDispatch, useSelector } from 'react-redux';
import {
    setIsLoading,
    selectShops,
    addAlert,
} from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import {
    hasDuplicates,
    validateNumberValue,
    findShopById,
    scrollTop,
    formatPrice,
} from 'helpers/global';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { selectUser } from 'context/features/user/userSlice';
import { CustomTypography } from 'styles/components';
import { clearSearchParams } from 'context/features/sale/saleSlice';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    row: {
        '&:hover': { cursor: 'pointer' },
    },
    addToDatabase: {
        margin: theme.spacing(1.4, 0, 0, 1.5),
    },
    marginB: {
        marginBottom: '4rem',
    },
}));

const TransferShoes = () => {
    const classes = useStyles();
    const [result, setResult] = useState([]);
    const [shoeFoundId, setShoeFound] = useState(null);
    const [sizes, setSizes] = useState([]);
    const dispatch = useDispatch();
    const { fromId, toId } = useParams();
    const shops = useSelector((state) => selectShops(state));
    const user = useSelector((state) => selectUser(state));
    const [shoeTitle, setShoeTitle] = useState('');
    const history = useHistory();
    const [allowTransfer, setAllowTransfer] = useState(true);

    useEffect(() => {
        setShoeFound(null);
        setSizes([]);
    }, [result]);

    const createShoeBlockHeader = (shoe: object) => {
        if (typeof shoe === 'object') {
            return `${shoe.brand} ${shoe.code} ${shoe.color} ${shoe.type}`;
        }
        return '';
    };


    const handleRemoveSizeOnClick = () => {
        setSizes([]
        );
    };

    const handleClickRowSelect = (rowId: number, _shoeTitle: string) => {
        setShoeFound(rowId !== shoeFoundId ? rowId : null);
        setShoeTitle(_shoeTitle);
        setTimeout(() => {
            scrollTop();
        }, 100);
        handleRemoveSizeOnClick();
    };

    const handleClickSaveSizes = async (e) => {
        e.preventDefault();

        const sizeInputVal = document.getElementById('size-input').value;
        const quantityInputVal = document.getElementById('quantity-input').value;

        if (
            sizes.length === 0 &&
            (!validateNumberValue(sizeInputVal) ||
                !validateNumberValue(quantityInputVal))
        ) {
            dispatch(
                addAlert({ content: 'Niekompletne rozmiary', type: 'error' })
            );
            return;
        }

        if (hasDuplicates(sizes.reduce((p, c) => p.concat(c.size), []))) {
            dispatch(
                addAlert({ content: 'Zdublikowane rozmiary', type: 'error' })
            );
            return;
        }

        if (!allowTransfer) {
            return;
        }
        setAllowTransfer(false);
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('put', `/transfer/${fromId}/${toId}`, {
                productId: shoeFoundId,
                sizes:
                    sizes.length > 0
                        ? sizes
                        : [
                              {
                                  size: parseInt(sizeInputVal, 10),
                                  quantity: parseInt(quantityInputVal, 10),
                              },
                          ],
            });

            setSizes([]);
            setResult([]);
            dispatch(
                addAlert({ content: 'Poprawnie przerzucono', type: 'success' }),
            );
            dispatch(clearSearchParams());
            
            if (+fromId === user.shopId) {
                history.push('/przerzut');
            } else {
                history.push('/duze-przerzuty');
            }
            
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
        setAllowTransfer(true);
    };

    const Title = () => {
        return (
            <>
                {'Przerzut obuwia z '}
                <CustomTypography
                    variant="h6"
                    component="span"
                    fontcolor={findShopById(shops, fromId).color}
                >
                    {findShopById(shops, fromId).name}
                </CustomTypography>
                {' do '}
                <CustomTypography
                    variant="h6"
                    component="span"
                    fontcolor={findShopById(shops, toId).color}
                >
                    {findShopById(shops, toId).name}
                </CustomTypography>
            </>
        );
    };

    return (
        <ViewContentCard title={<Title />}>
            {shoeFoundId !== null && (
                <div className={classes.marginB}>
                    <Typography variant="h6">{shoeTitle}</Typography>
                    <Grid container className={classes.grid}>
                        <Grid item xs={12} md={9} lg={9}>
                            <FormAddSizes sizes={sizes} setSizes={setSizes} fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Button
                                className={classes.addToDatabase}
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleClickSaveSizes}
                            >
                                {parseInt(fromId, 10) === user.shopId
                                    ? 'Wydaj'
                                    : 'Przyjmij'}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            )}

            <SearchBar setResult={setResult} />
            {result.length > 0 && (
                <SaleTableWithPagination
                    headers={[
                        text.CODE,
                        text.BRAND,
                        text.COLOR,
                        text.MODEL,
                        text.NORMAL_PRICE,
                        text.PROMOTIONAL_PRICE,
                    ]}
                    rowRender={(row) => (
                        <TableRow
                            className={classes.row}
                            onClick={() =>
                                handleClickRowSelect(
                                    row.id,
                                    createShoeBlockHeader(row)
                                )
                            }
                            hover
                            key={row.id}
                            selected={shoeFoundId === row.id}
                        >
                            <TableCell align="center">{row.code}</TableCell>
                            <TableCell align="center">{row.brand}</TableCell>
                            <TableCell align="center">{row.color}</TableCell>
                            <TableCell align="center">{row.type}</TableCell>
                            <TableCell align="center">
                                {formatPrice(row.primary_price)}
                            </TableCell>
                            <TableCell align="center">
                                {formatPrice(row.promotional_price)}
                            </TableCell>
                        </TableRow>
                    )}
                    rowsData={result}
                />
            )}
        </ViewContentCard>
    );
};

export default TransferShoes;
