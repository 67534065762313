// @flow
import React, { useState, useEffect } from 'react';
import {
    TableRow,
    TableCell,
    Button,
    makeStyles,
    IconButton,
    Grid,
} from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import {
    setIsLoading,
    addAlert,
    selectShops,
} from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { sendAuthAjax } from 'helpers/authorization';
import SaleTable from 'components/SaleTable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    addUser: {
        marginBottom: '10px',
    },
}));

const Workers = () => {
    const [users, setUsers] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const shops = useSelector((state) => selectShops(state));

    const getUsers = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('get', `/users`);

            setUsers(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickEditUser = (userId: number) => {
        history.push(`/pracownicy/edycja/${userId}`);
    };

    const handleClickNewUser = () => {
        history.push(`/pracownicy/dodanie`);
    };

    const getShopName = (shopId: number) => {
        const shop = shops.find((s) => s.id === parseInt(shopId, 10));

        return shop !== undefined ? shop.name : '';
    };

    const handleClickRemove = async (userId: number) => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('delete', `/users/${userId}`);
            dispatch(
                addAlert({
                    content: 'Poprawnie usunięto użytkownika',
                    type: 'success',
                })
            );
            setUsers(users.filter((user) => user.userId !== userId));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <ViewContentCard title={text.WORKERS}>
            <Grid
                container
                alignItems="center"
                spacing={1}
                className={classes.container}
            >
                <Grid item xs={12} md={6} lg={2}>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleClickNewUser}
                        className={classes.addUser}
                    >
                        Dodaj pracownika
                    </Button>
                </Grid>
            </Grid>
            {users && users.length > 0 && (
                <SaleTable
                    headers={[
                        text.REMOVE,
                        'ID',
                        text.USER_NAME,
                        text.FIRST_NAME,
                        text.LAST_NAME,
                        text.SHOP,
                        text.ROLE,
                        '',
                    ]}
                    rowRender={(row) => (
                        <TableRow hover key={row.userId}>
                            <TableCell align="center">
                                <IconButton
                                    onClick={() =>
                                        handleClickRemove(row.userId)
                                    }
                                >
                                    <DeleteOutlineIcon color="error" />
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">{row.userId}</TableCell>
                            <TableCell align="center">{row.userName}</TableCell>
                            <TableCell align="center">
                                {row.firstName}
                            </TableCell>
                            <TableCell align="center">{row.lastName}</TableCell>
                            <TableCell align="center">
                                {getShopName(row.shopId)}
                            </TableCell>
                            <TableCell align="center">
                                {text[row.roles[0]]}
                            </TableCell>
                            <TableCell align="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        handleClickEditUser(row.userId)
                                    }
                                >
                                    Edytuj
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                    rowsData={users}
                />
            )}
        </ViewContentCard>
    );
};

export default Workers;
