// @flow
import React, { useState } from 'react';
import {
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Select,
    MenuItem,
    TextField,
    Grid,
} from '@material-ui/core';
import {
    addAlert,
    selectShops,
    setIsLoading,
} from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'context/features/user/userSlice';
import { sendAuthAjax } from 'helpers/authorization';
import * as text from 'constants/strings';
import useIsGranted from 'hooks/useIsGranted';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingBottom: theme.spacing(2),
    },
    dialogTitle: {
        paddingBottom: 0,
    },
}));

const CustomShortageDialog = ({
    open,
    handleClose,
}: {
    open: boolean,
    handleClose: () => void,
}) => {
    const user = useSelector((state) => selectUser(state));
    const dispatch = useDispatch();
    const classes = useStyles();
    const shops = useSelector((state) => selectShops(state));
    const isGranted = useIsGranted();
    const disabled = !isGranted.hasOneOfRoles.execute([text.ROLE_MANAGER_CODE, text.ROLE_ADMIN_CODE]);
    const [data, setData] = useState({
        shopId: user.shopId || shops[0].id,
        customProduct: '',
    });

    const handleClickSave = async () => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('post', `/shortages`, data);
            dispatch(
                addAlert({ content: 'Poprawnie dodano', type: 'success' })
            );
            setData({ ...data, customProduct: '' });
            handleClose();
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    return (
        <Dialog
            className={classes.dialogTable}
            maxWidth={false}
            open={open}
            onClose={handleClose}
            style={{ maxHeight: '90vh' }}
        >
            <DialogTitle className={classes.dialogTitle}>
                Dodawanie niestandardowego produktu
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Select
                            fullWidth
                            disabled={disabled}
                            value={data.shopId}
                            onChange={(e) =>
                                setData({ ...data, shopId: e.target.value })
                            }
                        >
                            {shops.map((shop) => (
                                <MenuItem key={shop.id} value={shop.id}>
                                    {shop.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Opis"
                            multiline
                            rowsMax={4}
                            value={data.customProduct}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    customProduct: e.target.value,
                                })
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Anuluj
                </Button>
                <Button onClick={handleClickSave} color="primary">
                    Dodaj
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomShortageDialog;
