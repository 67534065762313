// @flow
/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        username: null,
        token: null,
        roles: [],
        ip: null,
        shopId: null,
        userId: null,
    },
    reducers: {
        loginUser: (state, action) => {
            state.username = action.payload.username;
            state.token = action.payload.token;
            state.roles = action.payload.roles;
            state.ip = action.payload.ip;
            state.shopId = action.payload.shopId;
            state.userId = action.payload.userId;
        },
        logoutUser: (state) => {
            state.username = null;
            state.token = null;
            state.roles = [];
            state.ip = null;
            state.shopId = null;
            state.userId = null;
        },
    },
});

export const selectUser = createSelector(
    (state) => state.user,
    (user) => user
);

export const { loginUser, logoutUser } = userSlice.actions;

export default userSlice.reducer;
