// @flow
import React, { useState, useEffect } from 'react';
import {
    TableRow,
    TableCell,
    makeStyles,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography
} from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import SearchBar from 'components/SearchBar';
import FormAddSizes from 'components/FormAddSizes';
import {
    setIsLoading,
    addAlert,
    selectShops,
} from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { sendAuthAjax } from 'helpers/authorization';
import { selectUser } from 'context/features/user/userSlice';
import { hasDuplicates, validateNumberValue } from 'helpers/global';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { scrollTop, formatPrice } from 'helpers/global';
import useIsGranted from 'hooks/useIsGranted';

const useStyles = makeStyles((theme) => ({
    row: {
        '&:hover': { cursor: 'pointer' },
    },
    addToDatabase: {
        margin: theme.spacing(1, 0, 0, 0),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
    marginB: {
        marginBottom: '4rem',
    },
    h6: {
        width: "100%",
        textAlign: 'center',
        marginTop: '1.5rem'
    }
}));

const AddSizes = () => {
    const classes = useStyles();
    const [result, setResult] = useState([]);
    const [shoeFoundId, setShoeFound] = useState(null);
    const [sizes, setSizes] = useState([]);
    const dispatch = useDispatch();
    const user = useSelector((state) => selectUser(state));
    const shops = useSelector((state) => selectShops(state));
    const [shopId, setShopId] = useState(user.shopId || shops[0].id);
    const [shoeTitle, setShoeTitle] = useState('');
    const isGranted = useIsGranted();

    useEffect(() => {
        setShoeFound(null);
        setSizes([]);
    }, [result]);

    const createShoeTitle = (shoe: object) => {
        if (shoe) {
            return `${shoe.brand} ${shoe.code} ${shoe.color} ${shoe.type}`;
        }
        return '';
    };

    const handleClickRowSelect = (row: object) => {
        setSizes([]);
        setShoeFound(row.id !== shoeFoundId ? row.id : null);
        setShoeTitle(createShoeTitle(row));
        setTimeout(() => {
            scrollTop();
        }, 100);
    };

    const handleClickSaveSizes = async (e) => {
        e.preventDefault();
        const sizeInputVal = document.getElementById('size-input').value;
        const quantityInputVal = document.getElementById('quantity-input')
            .value;

        if (
            sizes.length === 0 &&
            (!validateNumberValue(sizeInputVal) ||
                !validateNumberValue(quantityInputVal))
        ) {
            dispatch(
                addAlert({ content: 'Niekompletne rozmiary', type: 'error' })
            );
            return;
        }

        if (hasDuplicates(sizes.reduce((p, c) => p.concat(c.size), []))) {
            dispatch(
                addAlert({ content: 'Zdublikowane rozmiary', type: 'error' })
            );
            return;
        }

        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('post', `/products/${shoeFoundId}/inventories`, {
                shopId,
                sizes:
                    sizes.length > 0
                        ? sizes
                        : [
                              {
                                  size: parseInt(sizeInputVal, 10),
                                  quantity: parseInt(quantityInputVal, 10),
                              },
                          ],
            });
            dispatch(
                addAlert({
                    content: 'Poprawnie dodano rozmiary',
                    type: 'success',
                })
            );
            setSizes([]);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    return (
        <ViewContentCard title={text.ADDING_SIZES}>
            {shoeFoundId !== null && (
                <div className={classes.marginB}>
                    <Typography variant="h6" className={classes.h6}>
                        {shoeTitle}
                    </Typography>
                    {isGranted.hasOneOfRoles.execute([text.ROLE_MANAGER_CODE, text.ROLE_ADMIN_CODE]) && (
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <InputLabel id="demo-simple-select-label">
                                Sklep
                            </InputLabel>
                            <Select
                                label="Sklep"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={shopId}
                                onChange={(e) => setShopId(e.target.value)}
                            >
                                {shops.map((shop) => (
                                    <MenuItem key={shop.id} value={shop.id}>
                                        {shop.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <FormAddSizes
                        sizes={sizes}
                        setSizes={setSizes}
                        isAdditionalStandard
                    />
                    <Button
                        className={classes.addToDatabase}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleClickSaveSizes}
                    >
                        Dodaj do bazy
                    </Button>
                </div>
            )}

            <SearchBar setResult={setResult} />
            {result.length > 0 && (
                <SaleTableWithPagination
                    headers={[
                        text.CODE,
                        text.BRAND,
                        text.COLOR,
                        text.MODEL,
                        text.NORMAL_PRICE,
                        text.PROMOTIONAL_PRICE,
                    ]}
                    rowRender={(row) => (
                        <TableRow
                            className={classes.row}
                            onClick={() => handleClickRowSelect(row)}
                            hover
                            key={row.id}
                            selected={shoeFoundId === row.id}
                        >
                            <TableCell align="center">{row.code}</TableCell>
                            <TableCell align="center">{row.brand}</TableCell>
                            <TableCell align="center">{row.color}</TableCell>
                            <TableCell align="center">{row.type}</TableCell>
                            <TableCell align="center">{formatPrice(row.primary_price)}</TableCell>
                            <TableCell align="center">{formatPrice(row.promotional_price)}</TableCell>
                        </TableRow>
                    )}
                    rowsData={result}
                />
            )}
        </ViewContentCard>
    );
};

export default AddSizes;
