// @flow
import React, { useContext, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AddingInvoiceContext } from 'pages/Invoice';
import { sendAuthAjax } from 'helpers/authorization';
import * as text from 'constants/strings';

const CustomerSelect = () => {
    const {
        customers,
        selectCustomer,
        setSelectCustomer,
    } = useContext(AddingInvoiceContext);

    return (
        <Autocomplete
            size="small"
            value={selectCustomer}
            onChange={(e, customer) => {
                setSelectCustomer(customer);
            }}
            options={customers}
            getOptionLabel={(option) =>
                `${option.nip ? option.nip : ''} ${
                    option.companyName
                        ? option.companyName
                        : `${option.personName}`
                }`
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={text.CUSTOMER}
                    variant="outlined"
                />
            )}
            noOptionsText="brak"
        />
    );
};

export default CustomerSelect;
