// @flow
import React, { useContext, useState, useEffect } from 'react';
import {
    makeStyles,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';

import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { AddingInvoiceContext } from 'pages/Invoice';
import { paymentMethod, invoiceStatus, currencyCode } from 'constants/global';
import * as text from 'constants/strings';
import { useSelector } from 'react-redux';
import {
    selectShopsWithoutWarehouse,
    selectCurrencyRate
} from 'context/features/global/globalSlice';
import { selectUser } from 'context/features/user/userSlice';
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import { filterShopsWithoutEcommerce, convertToCurrency } from 'helpers/global';
import type {Shop, InvoiceData} from "../helpers/interface";

const useStyles = makeStyles(() => ({
    dialogTitle: {
        paddingBottom: 0,
    },
    featured: {
        color: green.A400,
    },
}));

const InvoiceDataBlock = () => {
    const {invoiceData, setInvoiceData, setOrders, id } = useContext(
        AddingInvoiceContext
    );
    const classes = useStyles();
    const [isEcommerce, setIsEcommerce] = useState(false);
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));
    const user = useSelector((state) => selectUser(state));
    const currencyRate = useSelector((state) => selectCurrencyRate(state));
    const currencyCodes = Object.values(currencyCode);

    useEffect(() => {
        setIsEcommerce(invoiceData.isEcommerce);
    }, [invoiceData]);

    const handleChangeCurrency = (currency) => {
        setInvoiceData((prev) => {
            return { ...prev, currency };
        });
        setOrders((prev) => {
            return prev.map((order) => {
                const price =
                    order.promotional_price > 0
                        ? order.promotional_price
                        : order.primary_price;
                
                const currencyPrice = convertToCurrency(price, currencyRate[currency], true);

                return { ...order, currency_price: currencyPrice, };
            });
        });
    };

    const handleEcommerceChange = (event: Event) => {
        setInvoiceData((prev: InvoiceData) => {
            return { ...prev, isEcommerce: event.target.checked };
        })
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid item xs={12} md={6} lg={3}>
                <TextField
                    disabled = {id == null ? true : false}
                    size="small"
                    label={text.NUMBER}
                    variant="outlined"
                    fullWidth
                    value={invoiceData.number}
                    onChange={(e) =>
                        setInvoiceData({
                            ...invoiceData,
                            number: e.target.value,
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <KeyboardDatePicker
                    clearLabel="Wyczyść"
                    cancelLabel="Anuluj"
                    inputVariant="outlined"
                    className={classes.formControl}
                    variant="dialog"
                    format="YYYY-MM-DD"
                    value={invoiceData.dateOfIssue}
                    onChange={(date) =>
                        setInvoiceData((prev) => {
                            return { ...prev, dateOfIssue: date };
                        })
                    }
                    label={text.DATE_OF_ISSUE}
                    clearable
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <KeyboardDatePicker
                    clearLabel="Wyczyść"
                    cancelLabel="Anuluj"
                    inputVariant="outlined"
                    className={classes.formControl}
                    variant="dialog"
                    format="YYYY-MM-DD"
                    value={invoiceData.dateOfPayment}
                    onChange={(date) =>
                        setInvoiceData((prev) => {
                            return { ...prev, dateOfPayment: date };
                        })
                    }
                    label={text.DATE_OF_PAYMENT}
                    clearable
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <KeyboardDatePicker
                    clearLabel="Wyczyść"
                    cancelLabel="Anuluj"
                    inputVariant="outlined"
                    className={classes.formControl}
                    variant="dialog"
                    format="YYYY-MM-DD"
                    value={invoiceData.dateOfSale}
                    onChange={(date) =>
                        setInvoiceData((prev) => {
                            return { ...prev, dateOfSale: date };
                        })
                    }
                    label={text.DATE_OF_SALE}
                    clearable
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="payment-method">
                        {text.PAYMENT_METHOD}
                    </InputLabel>
                    <Select
                        labelId="payment-method"
                        value={invoiceData.paymentType}
                        onChange={(e) =>
                            setInvoiceData((prev) => {
                                return { ...prev, paymentType: e.target.value };
                            })
                        }
                        label={text.PAYMENT_METHOD}
                    >
                        {Object.entries(paymentMethod).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="status">{text.STATUS}</InputLabel>
                    <Select
                        labelId="status"
                        value={invoiceData.status}
                        onChange={(e) =>
                            setInvoiceData((prev) => {
                                return { ...prev, status: e.target.value };
                            })
                        }
                        label={text.STATUS}
                    >
                        {Object.entries(invoiceStatus).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <TextField
                    size="small"
                    type="number"
                    label="Kwota wysyłki"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={invoiceData.shippingAmount}
                    onChange={(e) =>
                        setInvoiceData({
                            ...invoiceData,
                            shippingAmount: e.target.value,
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" variant="outlined" fullWidth>
                    <InputLabel id="currency">{text.CURRENCY}</InputLabel>
                    <Select
                        labelId="currency"
                        value={invoiceData.currency}
                        onChange={(e) => handleChangeCurrency(e.target.value)}
                        label={text.CURRENCY}
                    >
                        {currencyCodes.map((code) => (
                            <MenuItem value={currencyCode[code]}>{currencyCode[code]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" variant="outlined" fullWidth>
                    <InputLabel id="shopFrom">{text.FROM_SHOP}</InputLabel>
                    <Select
                        labelId="shopFrom"
                        value={invoiceData.shopFromId}
                        onChange={(e) =>
                            setInvoiceData((prev) => {
                                return { ...prev, shopFromId: e.target.value };
                            })
                        }
                        label={text.FROM_SHOP}
                        className={clsx(
                            invoiceData.shopFromId === user.shopId &&
                                classes.featured,
                            classes.shopsSelect
                        )}
                    >
                        {filterShopsWithoutEcommerce(shops, (shop: Shop) => (
                            <MenuItem
                                className={clsx(
                                    user.shopId === shop.id && classes.featured
                                )}
                                key={shop.id}
                                value={shop.id}
                            >
                                {shop.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" variant="outlined" fullWidth>
                <FormControlLabel
                    control={
                        <Checkbox 
                            checked={isEcommerce} 
                            onChange={handleEcommerceChange} 
                        />
                    }
                    label={text.IS_IT_ECOMMERCE}
                />
                </FormControl>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

export default InvoiceDataBlock;
