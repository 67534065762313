import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    useTheme,
    makeStyles,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import moment from 'moment';
import SalesDetailFilters from '../SalesDetailFilters';
import { map, range } from 'lodash';

const useStyles = makeStyles(() => ({
    cardHeader: {
        display: 'block',
    },
}));

const SalesModelDetail = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [filters, setFilters] = useState({
        rangeCheckbox: false,
        dateFrom: moment().startOf('month'),
        dateTo: moment(),
        period: 'lastMonth',
        shopId: '',
        currency: '',
    });

    const greenOrPurpleGradient = (shouldBePurple = false) => {
        return map(range(45, 255, 10), (i) => {
            return shouldBePurple ? `rgb(${i}, 0, ${i})` : `rgb(0, ${i}, 0)`;
        });
    };

    const options = {
        responsive: true,
        animation: false,
        cornerRadius: 20,
        layout: { padding: 0 },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            xAxes: [
                {
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                    },
                    gridLines: {
                        display: true,
                        drawBorder: true,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                        beginAtZero: true,
                        min: 0,
                    },
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider,
                    },
                },
            ],
        },
        tooltips: {
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary,
        },
    };

    const prepareBarData = (responseData) => {
        const labels = Object.keys(responseData);
        const quantities = map(labels, (key) => responseData[key].quantity);
        const amounts = map(labels, (key) => responseData[key].amount);

        const barData = {
            labels: labels,
            datasets: [
                {
                    label: 'Ilość',
                    data: quantities,
                    backgroundColor: greenOrPurpleGradient(true),
                    borderColor: greenOrPurpleGradient(true),
                    borderWidth: 1,
                    hidden: true,
                },
                {
                    label: 'Wartość',
                    data: amounts,
                    backgroundColor: greenOrPurpleGradient(),
                    borderColor: greenOrPurpleGradient(),
                    borderWidth: 1,
                },
            ],
        };

        return barData;
    };

    const getSalesFilters = async () => {
        if (filters.rangeCheckbox && filters.dateTo < filters.dateFrom) {
            dispatch(
                addAlert({
                    content: 'Data "do" nie może być mniejsza od daty "od"',
                    type: 'error',
                })
            );
            return;
        }

        dispatch(setIsLoading(true));
        const searchParams = new URLSearchParams();

        searchParams.append('dateFrom', filters.dateFrom.format('YYYY-MM-DD'));
        searchParams.append('dateTo', filters.dateTo.format('YYYY-MM-DD'));

        if (filters.currency) {
            searchParams.append('currency', filters.currency);
        }

        try {
            const responseCurrent = await sendAuthAjax(
                'get',
                '/reports/models',
                undefined,
                searchParams
            );
            setData(prepareBarData(responseCurrent.data));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getSalesFilters();
    }, [filters]);

    return (
        <Card className={clsx(classes.root)}>
            <CardHeader
                style={{ flexWrap: 'nowrap' }}
                className={classes.cardHeader}
                action={
                    <SalesDetailFilters
                        filters={filters}
                        setFilters={setFilters}
                        showShopsSelect={false}
                        showHourSelect={false}
                        showCurrencySelect={true}
                    />
                }
                title="Wartość zakupów"
            />
            <Divider />
            <CardContent>
                <Box height={400} position="relative">
                    {data && <Bar data={data} options={options} />}
                </Box>
            </CardContent>
        </Card>
    );
};

export default SalesModelDetail;
