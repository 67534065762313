import { AxiosError } from 'axios';
import { sendAuthAjax } from 'helpers/authorization';
import { Attendance, ChecklistCategory, ChecklistReport, Pagination } from 'helpers/interface';
import { useQuery, UseQueryOptions } from 'react-query';

export const key = 'checklistReportQuery';
const path = '/checklist-reports/';

const getChecklistReport = async (id: string) => {
    const { data } = await sendAuthAjax('get', path + id);
    return data;
};

export default function useChecklistReportQuery(id: string) {
    return useQuery<ChecklistReport, AxiosError>(
        [key, id],
        () => getChecklistReport(id),
        {enabled: !! id}
    );
}
