// @flow
import React, { useMemo, useState } from 'react';
import * as text from 'constants/strings';
import ViewContentCard from 'components/Layout/ViewContentCard';
import { sendAuthAjax } from 'helpers/authorization';
import { useDispatch, useSelector } from 'react-redux';
import {
    addAlert,
    selectShopsWithoutWarehouse,
    setIsLoading,
} from 'context/features/global/globalSlice';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    TextField,
    Paper,
    TableContainer,
    TableBody,
    Table,
    TableHead,
} from '@material-ui/core';
import { StyledTableCell } from 'styles/components';

import { useHistory } from 'react-router-dom';
import {
    statementSearchMode,
    statementShopOrderByOptions,
} from 'constants/global';
import useIsGranted from 'hooks/useIsGranted';
import { selectUser } from 'context/features/user/userSlice';
import { findShopById } from 'helpers/global';
import { productType } from 'constants/global';
import { map } from 'lodash';

const useStyles = makeStyles({
    tableWithDetail: {
        '& thead th, & tbody td': {
            padding: '6px',
            textAlign: 'center',
        },
    },
    highlightedValue: {
        fontWeight: 800,
    },
    emptyValue: {
        color: 'red',
        opacity: 0.3,
    },
    row: {
        '&:hover': { cursor: 'pointer' },
    },
    formControl: {
        margin: `8px 0px`,
    },
    searchTypeSelect: {
        width: '100%',
    },
});

const StatementShop = () => {
    const dispatch = useDispatch();
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));
    const isGranted = useIsGranted();
    const extendedView = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
    ]);
    const [searchParams, setSearchParams] = useState({
        date: new Date(),
        fromDate: new Date(),
        toDate: new Date(),
        code: '',
        brand: '',
        shopId: '',
        category: '',
        orderBy: statementShopOrderByOptions.sales,
    });
    const [result, setResult] = useState([]);
    const classes = useStyles();
    const history = useHistory();
    const [searchMode, setSearchMode] = useState(statementSearchMode.month);
    const user = useSelector(selectUser);

    const getStatement = async () => {
        let dateFrom = null;
        let dateTo = null;

        if (searchMode === statementSearchMode.month) {
            if (!moment(searchParams.date).isValid()) {
                return;
            }

            dateFrom = moment(searchParams.date)
                .startOf('month')
                .format('YYYY-MM-DD');
            dateTo = moment(searchParams.date)
                .endOf('month')
                .format('YYYY-MM-DD');
        } else if (searchMode === statementSearchMode.period) {
            if (
                !moment(searchParams.fromDate).isValid() ||
                !moment(searchParams.toDate).isValid()
            ) {
                return;
            }

            dateFrom = moment(searchParams.fromDate).format('YYYY-MM-DD');
            dateTo = moment(searchParams.toDate).format('YYYY-MM-DD');
        }

        if (!dateFrom || !dateTo) {
            return;
        }

        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/statement/sales/shop?dateFrom=${dateFrom}&dateTo=${dateTo}&code=${searchParams.code}&brand=${searchParams.brand}&shopId=${searchParams.shopId}&orderBy=${searchParams.orderBy}&category=${searchParams.category}`
            );
            setResult(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickSearch = (e) => {
        e.preventDefault();
        getStatement();
    };

    const handleSubmitEnter = (e) => {
        if (e.key === 'Enter') {
            getStatement();
        }
    };

    const handleClickRow = (rowId: number) => {
        if (!extendedView) {
            return;
        }

        history.push(`/historia-poszczegolnego-modelu/${rowId}`);
    };

    const getIndex = (row) => {
        return (
            result.products.findIndex(
                (item) => item.productId === row.productId
            ) + 1
        );
    };

    const getHeaders = () => {
        let headers = ['', text.CODE, text.BRAND, text.COLOR, text.MODEL];

        if (extendedView) {
            headers = headers.concat([
                text.SALE,
                text.BEST_SELLING_SIZE,
                text.TOTAL_PLN,
            ]);
        }

        return headers;
    };

    const subtotal = (items) => {
        return map(items, ({ sales }) => sales).reduce((sum, i) => sum + i, 0);
    };

    const invoiceSubtotal =
        extendedView && result?.products ? subtotal(result.products) : 0;

    const getShops = () => {
        if (extendedView) {
            return shops;
        }

        return [findShopById(shops, user.shopId)];
    };

    const groupSoldBySize = (soldBySize) => {
        const groups = {
            '20-30': 0,
            '31-35': 0,
            '36-41': 0,
            '42-46': 0,
            '47-50': 0,
            inne: 0,
        };

        Object.keys(soldBySize).map((key) => {
            switch (true) {
                case key >= 20 && key <= 30:
                    groups['20-30'] += soldBySize[key];
                    break;
                case key >= 31 && key <= 35:
                    groups['31-35'] += soldBySize[key];
                    break;
                case key >= 36 && key <= 41:
                    groups['36-41'] += soldBySize[key];
                    break;
                case key >= 42 && key <= 46:
                    groups['42-46'] += soldBySize[key];
                    break;
                case key >= 47 && key <= 50:
                    groups['47-50'] += soldBySize[key];
                    break;
                default:
                    groups['inne'] += soldBySize[key];
            }
        });

        return groups;
    };

    const tableRender = (rows) => {
        if (!rows?.products) {
            return null;
        }

        const soldBySizeGroups = rows?.soldBySize
            ? groupSoldBySize(rows.soldBySize)
            : null;

        return (
            <Grid item xs={12}>
                {rows.products.length > 0 && (
                    <div className={classes.root}>
                        <TableContainer
                            component={Paper}
                            className={classes.container}
                        >
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        {getHeaders().map((head) => (
                                            <StyledTableCell
                                                key={`head-${head}`}
                                            >
                                                {head}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.products.map((row) => (
                                        <TableRow
                                            className={classes.row}
                                            key={row.productId}
                                            onClick={() =>
                                                handleClickRow(row.productId)
                                            }
                                            hover
                                        >
                                            <TableCell>
                                                {getIndex(row)}
                                            </TableCell>
                                            <TableCell
                                                className={classes.contentCell}
                                                align="center"
                                            >
                                                {row.productCode}
                                            </TableCell>
                                            <TableCell
                                                className={classes.contentCell}
                                                align="center"
                                            >
                                                {row.productBrand}
                                            </TableCell>
                                            <TableCell
                                                className={classes.contentCell}
                                                align="center"
                                            >
                                                {row.productColor}
                                            </TableCell>
                                            <TableCell
                                                className={classes.contentCell}
                                                align="center"
                                            >
                                                {row.productType}
                                            </TableCell>
                                            {extendedView && (
                                                <>
                                                    <TableCell
                                                        className={
                                                            classes.contentCell
                                                        }
                                                        align="center"
                                                    >
                                                        {row.sales}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.contentCell
                                                        }
                                                        align="center"
                                                    >
                                                        {row.bestSellingSize}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.contentCell
                                                        }
                                                        align="center"
                                                    >
                                                        {row.totalPLN}
                                                    </TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    ))}
                                    {extendedView && soldBySizeGroups && (
                                        <>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell />
                                                <TableCell />
                                                <TableCell />
                                                <TableCell align="right">
                                                    Łącznie
                                                </TableCell>
                                                <TableCell align="center">
                                                    {invoiceSubtotal}
                                                </TableCell>
                                                <TableCell />
                                                <TableCell />
                                            </TableRow>
                                            {Object.keys(soldBySizeGroups).map(
                                                (key) => (
                                                    <TableRow key={key}>
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell align="right">
                                                            {key}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                soldBySizeGroups[
                                                                    key
                                                                ]
                                                            }
                                                        </TableCell>
                                                        <TableCell />
                                                        <TableCell />
                                                    </TableRow>
                                                )
                                            )}
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </Grid>
        );
    };

    const table = useMemo(() => tableRender(result), [result]);

    return (
        <ViewContentCard title={text.STATEMENT_SHOP}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} md={2} lg={1}>
                        <FormControl className={classes.searchTypeSelect}>
                            <Select
                                value={searchMode}
                                onChange={(e) => setSearchMode(e.target.value)}
                            >
                                <MenuItem value={statementSearchMode.month}>
                                    {statementSearchMode.month}
                                </MenuItem>
                                <MenuItem value={statementSearchMode.period}>
                                    {statementSearchMode.period}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {searchMode === statementSearchMode.month && (
                        <Grid item xs={12} md={10} lg={4}>
                            <KeyboardDatePicker
                                fullWidth
                                label="Data"
                                size="small"
                                views={['year', 'month']}
                                variant="inline"
                                inputVariant="outlined"
                                value={searchParams.date}
                                onChange={(date) =>
                                    setSearchParams({ ...searchParams, date })
                                }
                            />
                        </Grid>
                    )}
                    {searchMode === statementSearchMode.period && (
                        <>
                            <Grid item xs={12} md={5} lg={2}>
                                <KeyboardDatePicker
                                    autoOk
                                    fullWidth
                                    label="Od"
                                    size="small"
                                    format="YYYY-MM-DD"
                                    variant="inline"
                                    inputVariant="outlined"
                                    value={searchParams.fromDate}
                                    onChange={(fromDate) =>
                                        setSearchParams({
                                            ...searchParams,
                                            fromDate,
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={5} lg={2}>
                                <KeyboardDatePicker
                                    autoOk
                                    fullWidth
                                    label="Do"
                                    size="small"
                                    format="YYYY-MM-DD"
                                    variant="inline"
                                    inputVariant="outlined"
                                    value={searchParams.toDate}
                                    minDate={searchParams.fromDate}
                                    onChange={(toDate) =>
                                        setSearchParams({
                                            ...searchParams,
                                            toDate,
                                        })
                                    }
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} md={6} lg={2}>
                        <TextField
                            fullWidth
                            size="small"
                            value={searchParams.code}
                            onChange={(e) =>
                                setSearchParams({
                                    ...searchParams,
                                    code: e.target.value,
                                })
                            }
                            label="kod"
                            variant="outlined"
                            onKeyDown={handleSubmitEnter}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                        <TextField
                            fullWidth
                            size="small"
                            value={searchParams.brand}
                            onChange={(e) =>
                                setSearchParams({
                                    ...searchParams,
                                    brand: e.target.value,
                                })
                            }
                            label="marka"
                            variant="outlined"
                            onKeyDown={handleSubmitEnter}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={1}>
                        <FormControl
                            className={classes.formControl}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                        >
                            <InputLabel shrink id="content-select-label">
                                Kategoria
                            </InputLabel>
                            <Select
                                labelId="content-select-label"
                                value={searchParams.category}
                                label="Kategoria"
                                displayEmpty
                                onChange={(e) =>
                                    setSearchParams({
                                        ...searchParams,
                                        category: e.target.value,
                                    })
                                }
                            >
                                <MenuItem value="">Wszystko</MenuItem>
                                {map(productType, (value, key) => (
                                    <MenuItem key={key} value={key}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={1}>
                        <FormControl
                            className={classes.formControl}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                        >
                            <InputLabel shrink id="content-select-label">
                                Sklep
                            </InputLabel>
                            <Select
                                labelId="content-select-label"
                                value={searchParams.shopId}
                                label="Sklep"
                                displayEmpty
                                onChange={(e) =>
                                    setSearchParams({
                                        ...searchParams,
                                        shopId: e.target.value,
                                    })
                                }
                            >
                                <MenuItem value="">Wszystko</MenuItem>
                                {map(getShops(), (shop) => (
                                    <MenuItem key={shop.id} value={shop.id}>
                                        {shop.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {extendedView && (
                        <Grid item xs={12} md={6} lg={1}>
                            <FormControl
                                className={classes.formControl}
                                fullWidth
                                margin="dense"
                                variant="outlined"
                            >
                                <InputLabel shrink id="content-select-label">
                                    Sortuj wg
                                </InputLabel>
                                <Select
                                    labelId="content-select-label"
                                    value={searchParams.orderBy}
                                    label="Sortuj wg"
                                    displayEmpty
                                    onChange={(e) =>
                                        setSearchParams({
                                            ...searchParams,
                                            orderBy: e.target.value,
                                        })
                                    }
                                >
                                    <MenuItem
                                        value={
                                            statementShopOrderByOptions.sales
                                        }
                                    >
                                        Sprzedaż
                                    </MenuItem>
                                    <MenuItem
                                        value={
                                            statementShopOrderByOptions.totalPLN
                                        }
                                    >
                                        Łącznie zł
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6} lg={1}>
                        <Button
                            fullWidth
                            onClick={handleClickSearch}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            szukaj
                        </Button>
                    </Grid>
                    {table}
                </Grid>
            </MuiPickersUtilsProvider>
        </ViewContentCard>
    );
};

export default StatementShop;
