// @flow
import React, { useState, useEffect } from 'react';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import SearchBar from 'components/SearchBar';
import FindShoeBlock from 'components/FindShoeBlock';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { scrollTop, formatPrice, createShoeBlockHeader } from 'helpers/global';

const useStyles = makeStyles(() => ({
    row: {
        '&:hover': { cursor: 'pointer' },
    },
    marginB: {
        marginBottom: '4rem',
    }
}));

const FindShoes = () => {
    const classes = useStyles();
    const [result, setResult] = useState([]);
    const [shoeFoundId, setShoeFound] = useState(null);
    const [shoeTitle, setShoeTitle] = useState('');

    useEffect(() => {
        setShoeFound(null);
    }, [result]);

    const handleRowClick = (rowId: number, _shoeTitle: string) => {
        setShoeFound(rowId !== shoeFoundId ? rowId : null);
        setShoeTitle(_shoeTitle);
        setTimeout(() => {
            scrollTop();
        }, 100);
    };

    return (
        <ViewContentCard title={text.FIND_SHOES}>
            {shoeFoundId !== null &&
                <div className={classes.marginB}>
                    <FindShoeBlock shoeId={shoeFoundId} title={shoeTitle} />
                </div>
            }
            
            <SearchBar setResult={setResult} />
            {result.length > 0 && (
                <SaleTableWithPagination
                    headers={
                        [
                            text.CODE,
                            text.BRAND,
                            text.COLOR,
                            text.MODEL,
                            text.NORMAL_PRICE,
                            text.PROMOTIONAL_PRICE,
                        ]
                    }
                    rowRender={(row) => (
                        <TableRow
                            className={classes.row}
                            onClick={() => handleRowClick(row.id, createShoeBlockHeader(row))}
                            hover
                            key={row.id}
                            selected={shoeFoundId === row.id}
                        >
                            <TableCell align="center">{row.code}</TableCell>
                            <TableCell align="center">{row.brand}</TableCell>
                            <TableCell align="center">{row.color}</TableCell>
                            <TableCell align="center">{row.type}</TableCell>
                            <TableCell align="center">{formatPrice(row.primary_price)}</TableCell>
                            <TableCell align="center">{formatPrice(row.promotional_price)}</TableCell>
                        </TableRow>
                    )}
                    rowsData={result}
                />
            )}
        </ViewContentCard>
    );
};

export default FindShoes;
