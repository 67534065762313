import 'moment/locale/pl';
import React from 'react';
import styled from 'styled-components/macro';

const Block = styled.div`
    color: ${({ color }) => color || 'inherit'};
`;

const GraphicAttendanceSchedulesBlock = ({ shopScheduleSheet }) => {
    const scheduled = shopScheduleSheet?.scheduledEmployeeQuantity ?? 0;
    const used = shopScheduleSheet?.actuallyEmployeeQuantity ?? 0;

    return (
        <Block color="#ffffff">
            {scheduled > 0 && (
                <div alignItems="center">
                    <div>Obłożenie:</div>
                    <div>{`${used} / ${scheduled}`}</div>
                </div>
            )}
        </Block>
    );
};

export default GraphicAttendanceSchedulesBlock;
