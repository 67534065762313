// @flow
import React, { useContext } from 'react';
import {
    TableRow,
    TableCell,
    Button,
    makeStyles,
    IconButton,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    Grid,
} from '@material-ui/core';
import * as text from 'constants/strings';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { StyledTableCell } from 'styles/components';
import { AddingComplaintContext } from 'pages/Complaint';
import { formatNumber } from 'helpers/global';

const useStyles = makeStyles((theme) => ({
    addProduct: {
        margin: `${theme.spacing(1)}px 0px`,
    },
    table: {
        margin: `${theme.spacing(1)}px 0px`,
    },
}));

const headers = [
    text.REMOVE,
    text.CODE,
    text.BRAND,
    text.COLOR,
    text.MODEL,
    text.NORMAL_PRICE,
    text.PROMOTIONAL_PRICE,
    text.SIZE,
];

const ComplaintProductBlock = () => {
    const { setProductsDialogOpen, setProduct, product, complaintData } = useContext(
        AddingComplaintContext
    );
    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} md={3} lg={2}>
                <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="secondary"
                    onClick={() => setProductsDialogOpen(true)}
                    className={classes.addProduct}
                >
                    Dodaj produkt
                </Button>
            </Grid>
            <Grid item xs={12}>
                {product !== null && (
                    <TableContainer component={Paper} className={classes.table}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {headers.map((head) => (
                                        <StyledTableCell key={`head-${head}`}>
                                            {head}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow hover>
                                    <TableCell align="center">
                                        <IconButton
                                            onClick={() => setProduct(null)}
                                        >
                                            <DeleteOutlineIcon color="error" />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">
                                        {product.code}
                                    </TableCell>
                                    <TableCell align="center">
                                        {product.brand}
                                    </TableCell>
                                    <TableCell align="center">
                                        {product.color}
                                    </TableCell>
                                    <TableCell align="center">
                                        {product.type}
                                    </TableCell>
                                    <TableCell align="center">
                                        {formatNumber(product.primary_price)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {formatNumber(
                                            product.promotional_price
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {complaintData.size}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>
        </>
    );
};

export default ComplaintProductBlock;
