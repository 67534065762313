import {
    Button,
    ButtonGroup,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { localicationToShopMap } from 'constants/global';
import {
    addAlert,
    selectShopsWithoutWarehouse,
    setIsLoading,
} from 'context/features/global/globalSlice';
import { selectUser } from 'context/features/user/userSlice';
import { sendAuthAjax } from 'helpers/authorization';
import { Addition as AdditionType, Shop, Sort } from 'helpers/interface';
import useAdditionQuery, { key } from 'hooks/useAdditionQuery';
import { filter, get, map, reduce } from 'lodash';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import AdditionTable from './AdditionTable';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    btn: {
        height: '40px',
    },
    btnGroup: {
        marginTop: '3px',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const Addition = () => {
    const queryClient = useQueryClient();
    const classes = useStyles();
    const [selected, setSelected] = useState<number[]>([]);
    const [selectedShop, setSelectedShop] = useState('all');
    const { localization } = useParams<{ localization: string }>();
    const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(
        moment()
    );
    const [sort, setSort] = useState<Sort | null>({
        key: 'product_type',
        direction: 'asc',
    });
    const dispatch = useDispatch();
    const user = useSelector((state) => selectUser(state));
    const shops = useSelector((state) =>
        selectShopsWithoutWarehouse(state)
    ) as Shop[];

    const shopsPerLocalization = useMemo(() => {
        const order = ['Poraj', 'Sukiennice', 'Krupówki', 'Przejście', 'Rynek'];

        let shopsByLocalization = filter(
            shops,
            (shop) =>
                shop.localization.toLowerCase() ===
                    localization.toLowerCase() ||
                get(localicationToShopMap, localization)?.includes(shop.name)
        ) as Shop[];

        shopsByLocalization.sort(function (x, y) {
            return order.indexOf(x.name) - order.indexOf(y.name);
        });

        return shopsByLocalization;
    }, [shops, localization, localicationToShopMap]);

    const preparedUrl = useMemo(() => {
        let url = `/addition?date=${moment(selectedDate).format(
            'YYYY-MM-DD'
        )}&shopFromId=${user.shopId}`;

        if (selectedShop !== 'all') {
            url += `&shopsToId[]=${selectedShop}`;
        } else {
            url += reduce(
                shopsPerLocalization,
                (p, c) => p.concat(`&shopsToId[]=${c.id}`),
                ''
            );
        }
        if (sort) {
            url += `&order=${sort.key} ${sort.direction}`;
        }

        return url;
    }, [selectedDate, user.shopId, selectedShop, shopsPerLocalization, sort]);

    const { data, isLoading, isFetching } = useAdditionQuery(
        { url: preparedUrl },
        { enabled: moment(selectedDate).isValid() }
    );

    useEffect(() => {
        setSelectedShop('all');
    }, [localization]);

    const loading = isLoading || isFetching;

    const handleRemove = useCallback(async () => {
        if (selected.length < 1) return;
        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax('delete', '/addition', {
                additionsId: selected,
            });
            queryClient.invalidateQueries([key]);
            setSelected([]);
            dispatch(
                addAlert({ content: 'Poprawnie dołożono', type: 'success' })
            );
        } catch (error) {
            const errorText = get(error, 'response.data.message')
                ? get(error, 'response.data.message')
                : get(error, 'response.statusText');
            dispatch(addAlert({ content: errorText, type: 'error' }));
        } finally {
            dispatch(setIsLoading(false));
        }
    }, [
        selected,
        dispatch,
        sendAuthAjax,
        queryClient,
        key,
        setSelected,
        addAlert,
        setIsLoading,
    ]);

    const handleRowClick = useCallback(
        (
            _: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
            row: AdditionType
        ) => {
            const selectedIndex = selected.indexOf(row.id);
            let newSelected: number[] = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, row.id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1)
                );
            }

            setSelected(newSelected);
        },
        [selected, setSelected]
    );

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ViewContentCard title={text.ADDITION}>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} md={4} lg={3}>
                        <ButtonGroup
                            fullWidth
                            orientation="horizontal"
                            color="primary"
                            variant="outlined"
                            className={classes.btnGroup}
                        >
                            <Button
                                to="/dolozenie/zakopane"
                                color={
                                    localization === 'zakopane'
                                        ? 'secondary'
                                        : 'primary'
                                }
                                component={NavLink}
                                className={classes.btn}
                            >
                                ZAKOPANE
                            </Button>
                            <Button
                                color={
                                    localization === 'wisla'
                                        ? 'secondary'
                                        : 'primary'
                                }
                                to="/dolozenie/wisla"
                                component={NavLink}
                                className={classes.btn}
                            >
                                WISŁA
                            </Button>
                            <Button
                                color={
                                    localization === 'karpacz'
                                        ? 'secondary'
                                        : 'primary'
                                }
                                to="/dolozenie/karpacz"
                                component={NavLink}
                                className={classes.btn}
                            >
                                KARPACZ
                            </Button>
                            <Button
                                color={
                                    localization === 'bialka'
                                        ? 'secondary'
                                        : 'primary'
                                }
                                to="/dolozenie/bialka"
                                component={NavLink}
                                className={classes.btn}
                            >
                                BIAŁKA
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                        <KeyboardDatePicker
                            margin="dense"
                            fullWidth
                            inputVariant="outlined"
                            variant="inline"
                            format="YYYY-MM-DD"
                            value={selectedDate}
                            onChange={(date) => {
                                console.log(date, 'date');
                                setSelectedDate(date);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                        <FormControl
                            fullWidth
                            margin="dense"
                            variant="outlined"
                        >
                            <InputLabel shrink id="shop-select-label">
                                Sklep
                            </InputLabel>
                            <Select
                                labelId="shop-select-label"
                                value={selectedShop}
                                label="Sklep"
                                onChange={(e) => {
                                    setSelectedShop(e.target.value as string);
                                }}
                            >
                                <MenuItem value="all">Wszystkie</MenuItem>
                                {map(shopsPerLocalization, (shop) =>
                                    shop.short !== 'si' ? (
                                        <MenuItem key={shop.id} value={shop.id}>
                                            {shop.name}
                                        </MenuItem>
                                    ) : null
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <AdditionTable
                    onRemove={handleRemove}
                    sort={sort}
                    setSort={setSort}
                    setSelected={setSelected}
                    selected={selected}
                    data={data}
                    loading={loading}
                    onRowClick={handleRowClick}
                />
            </ViewContentCard>
        </MuiPickersUtilsProvider>
    );
};

export default Addition;
