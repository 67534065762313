import { AxiosError } from 'axios';
import { sendAuthAjax } from 'helpers/authorization';
import { AttendanceDto } from 'helpers/interface';
import { useMutation, UseMutationOptions } from 'react-query';

const path = '/attendance';

const postAttendance = async (payload: AttendanceDto) => {
    const { data } = await sendAuthAjax('post', path, payload);
    return data;
};

export default function useAddAttendanceMutation(
    options: UseMutationOptions<void, AxiosError, AttendanceDto> = {}
) {
    return useMutation<void, AxiosError, AttendanceDto>(
        postAttendance,
        options
    );
}
