// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as text from 'constants/strings';
import ViewContentCard from 'components/Layout/ViewContentCard';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    FormControl,
    Select,
    Box,
    MenuItem,
    makeStyles,
    TableRow,
    TableCell,
} from '@material-ui/core';
import {
    setIsLoading,
    addAlert,
    selectShops,
} from 'context/features/global/globalSlice';
import SaleTable from 'components/SaleTable';
import { sendAuthAjax } from 'helpers/authorization';
import moment from 'moment';
import { filterShopsWithoutEcommerce, findColorShopByName } from 'helpers/global';
import type { Shop } from "../helpers/interface";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        marginTop: theme.spacing(2),
    },
}));

const Logs = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [filter, setFilter] = useState({ date: new Date(), shopId: '' });
    const [logs, setLogs] = useState([]);
    const shops = useSelector((state) => selectShops(state));

    const getLogs = async () => {
        const date = moment(filter.date).format('YYYY-MM-DD');

        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/logs?date=${date}&shopId=${filter.shopId}`
            );
            setLogs(response.data);

        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getLogs();
    }, [filter]);

    return (
        <ViewContentCard title={text.LOGS}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <>
                    <Box display="flex">
                        <KeyboardDatePicker
                            variant="inline"
                            format="YYYY-MM-DD"
                            margin="normal"
                            value={filter.date}
                            onChange={(date) => setFilter({ ...filter, date })}
                        />
                        <FormControl className={classes.formControl}>
                            <Select
                                displayEmpty
                                value={filter.shopId}
                                onChange={(e) =>
                                    setFilter({
                                        ...filter,
                                        shopId: e.target.value,
                                    })
                                }
                            >
                                <MenuItem value="">Wszystko</MenuItem>
                                {filterShopsWithoutEcommerce(shops, (shop: Shop) => (
                                    <MenuItem key={shop.id} value={shop.id}>
                                        {shop.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {logs.length > 0 && (
                        <SaleTable
                            headers={[
                                'treść',
                                'sklep',
                                'uzytkownik',
                                'ip',
                                'godzina',
                            ]}
                            rowRender={(row) => (
                                <TableRow 
                                    key={row.id} 
                                    className={classes.row}
                                    bgcolor={findColorShopByName(shops, row.shop_name)}
                                >
                                    <TableCell
                                        style={{ width: '70%' }}
                                        align="center"
                                    >
                                        {row.message}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.shop_name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.username}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.ip}
                                    </TableCell>
                                    <TableCell align="center">
                                        {moment(row.createdAt).format(
                                            'HH:mm:ss'
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                            rowsData={logs}
                        />
                    )}
                </>
            </MuiPickersUtilsProvider>
        </ViewContentCard>
    );
};

export default Logs;
