// @flow
import React, { useEffect, useRef } from 'react';
import { Button, makeStyles, Grid, TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectSaleSearch,
    setSearchParam,
    clearSearchParams,
} from 'context/features/sale/saleSlice';
import { sendAuthAjax } from 'helpers/authorization';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { productTypeCode } from 'constants/global';

const useStyles = makeStyles(() => ({
    container: {
        margin: '10px 0px',
        width: '100%',
    },
    input: {
        marginRight: '10px',
    },
}));

type Props = { 
    showShoperVariantId: boolean,
    getProducts: () => void,
    productType: string,
    onClearParams: () => void,
    onSearchSuccess: (responseData: array) => void
};

const SaleSearchBar = ({ 
    showShoperVariantId = false,
    getProducts = null,
    productType = productTypeCode.shoe,
    onClearParams = () => {},
    onSearchSuccess = () => {}
}: Props) => {
    const classes = useStyles();
    const saleSearch = useSelector((state) => selectSaleSearch(state));
    const dispatch = useDispatch();
    const contentInputRef = useRef(null);

    const getProductsHandler = async () => { 
        if (getProducts) {
            getProducts();
            return;
        }
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'post',
                `/products/search/sale-shoes`,
                { ...saleSearch, productType }
            );

            onSearchSuccess(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        dispatch(clearSearchParams());
        contentInputRef.current.focus();
    }, []);

    const handleOnClick = (e) => {
        e.preventDefault();
        getProductsHandler();
    };

    const handleSubmitEnter = (e) => {
        if (e.key === 'Enter') {
            getProductsHandler();
        }
    };

    return (
        <Grid
            container
            alignItems="center"
            spacing={1}
            className={classes.container}
        >
            <Grid item xs={12} md={6} lg={2}>
                <TextField
                    fullWidth
                    size="small"
                    value={saleSearch.code}
                    onChange={(e) =>
                        dispatch(
                            setSearchParam({
                                type: 'code',
                                value: e.target.value,
                            })
                        )
                    }
                    label="kod"
                    variant="outlined"
                    className={classes.input}
                    onKeyDown={handleSubmitEnter}
                    inputRef={contentInputRef}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <TextField
                    fullWidth
                    size="small"
                    value={saleSearch.brand}
                    onChange={(e) =>
                        dispatch(
                            setSearchParam({
                                type: 'brand',
                                value: e.target.value,
                            })
                        )
                    }
                    label="marka"
                    variant="outlined"
                    className={classes.input}
                    onKeyDown={handleSubmitEnter}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <TextField
                    fullWidth
                    size="small"
                    value={saleSearch.color}
                    onChange={(e) =>
                        dispatch(
                            setSearchParam({
                                type: 'color',
                                value: e.target.value,
                            })
                        )
                    }
                    label="kolor"
                    variant="outlined"
                    className={classes.input}
                    onKeyDown={handleSubmitEnter}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <TextField
                    fullWidth
                    size="small"
                    value={saleSearch.type}
                    onChange={(e) =>
                        dispatch(
                            setSearchParam({
                                type: 'type',
                                value: e.target.value,
                            })
                        )
                    }
                    label="model"
                    variant="outlined"
                    className={classes.input}
                    onKeyDown={handleSubmitEnter}
                />
            </Grid>
            {showShoperVariantId === true &&
                <Grid item xs={12} md={6} lg={1}>
                    <TextField
                        fullWidth
                        size="small"
                        value={saleSearch.shoperVariantId}
                        onChange={(e) =>
                            dispatch(
                                setSearchParam({
                                    type: 'shoperVariantId',
                                    value: e.target.value,
                                })
                            )
                        }
                        label="Shoper ID"
                        variant="outlined"
                        className={classes.input}
                        onKeyDown={handleSubmitEnter}
                    />
                </Grid>
            }
            <Grid item xs={12} md={6} lg={1}>
                <Button
                    fullWidth
                    onClick={handleOnClick}
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    szukaj
                </Button>
            </Grid>

            <Grid item xs={12} md={6} lg={showShoperVariantId === true ? 2 : 3}>
                <Button
                    fullWidth
                    onClick={() => {
                        dispatch(clearSearchParams());
                        onClearParams();
                    }}
                    variant="contained"
                    color="primary"
                >
                    wyczyść wyszukiwarke
                </Button>
            </Grid>
        </Grid>
    );
};

export default SaleSearchBar;
