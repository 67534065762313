// @flow
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Line } from 'react-chartjs-2';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    useTheme,
    makeStyles,
    colors,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import SalesDetailFilters from '../SalesDetailFilters';

const useStyles = makeStyles(() => ({
    cardHeader: {
        display: 'block',
    },
}));

const SalesTimeDetail = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [filters, setFilters] = useState({
        rangeCheckbox: false,
        dateFrom: moment(),
        dateTo: moment(),
        hoursCheckbox: false,
        timeFrom: moment().hours(8),
        timeTo: moment().hours(12),
        period: 'today',
        shopId: '',
    });

    const options = {
        animation: false,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: false },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            xAxes: [
                {
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                    },
                    gridLines: {
                        display: true,
                        drawBorder: true,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                        beginAtZero: true,
                        min: 0,
                    },
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider,
                    },
                },
            ],
        },
    };

    const timeLabels = [
        { time: '01:00', key: 1 },
        { time: '02:00', key: 2 },
        { time: '03:00', key: 3 },
        { time: '04:00', key: 4 },
        { time: '05:00', key: 5 },
        { time: '06:00', key: 6 },
        { time: '07:00', key: 7 },
        { time: '08:00', key: 8 },
        { time: '09:00', key: 9 },
        { time: '10:00', key: 10 },
        { time: '11:00', key: 11 },
        { time: '12:00', key: 12 },
        { time: '13:00', key: 13 },
        { time: '14:00', key: 14 },
        { time: '15:00', key: 15 },
        { time: '16:00', key: 16 },
        { time: '17:00', key: 17 },
        { time: '18:00', key: 18 },
        { time: '19:00', key: 19 },
        { time: '20:00', key: 20 },
        { time: '21:00', key: 21 },
        { time: '22:00', key: 22 },
        { time: '23:00', key: 23 },
    ];

    const prepareBarData = (responseData) => {
        const barData = {
            datasets: [
                {
                    label: 'Wartość',
                    fill: false,
                    lineTension: 0.1,
                    lineWidth: 5,
                    backgroundColor: colors.indigo[500],
                    borderColor: colors.indigo[500],
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: colors.indigo[500],
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: colors.indigo[500],
                    pointHoverBorderColor: colors.indigo[500],
                    pointHoverBorderWidth: 2,
                    pointRadius: 4,
                    pointHitRadius: 0,
                    data: [],
                },
            ],
            labels: [],
        };

        timeLabels.forEach(({ time, key }) => {
            if (responseData[key] !== undefined) {
                barData.labels.push(time);
                barData.datasets[0].data.push(responseData[key]);
            }
        });

        return barData;
    };

    const getSalesShops = async () => {
        if (filters.rangeCheckbox && filters.dateTo < filters.dateFrom) {
            dispatch(
                addAlert({
                    content: 'Data "do" nie może być mniejsza od daty "od"',
                    type: 'error',
                })
            );
            return;
        }

        if (filters.hoursCheckbox && filters.timeTo < filters.timeFrom) {
            dispatch(
                addAlert({
                    content:
                        'Godzina "do" nie może być mniejsza od godziny "od"',
                    type: 'error',
                })
            );
            return;
        }

        dispatch(setIsLoading(true));

        const searchParams = new URLSearchParams();

        searchParams.append('dateFrom', filters.dateFrom.format('YYYY-MM-DD'));
        searchParams.append('dateTo', filters.dateTo.format('YYYY-MM-DD'));

        if (filters.hoursCheckbox) {
            searchParams.append('timeFrom', filters.timeFrom.format('H'));
            searchParams.append('timeTo', filters.timeTo.format('H'));
        }

        if (filters.shopId) {
            searchParams.append('shopId', filters.shopId);
        }

        try {
            const responseCurrent = await sendAuthAjax(
                'get',
                '/reports/time',
                undefined,
                searchParams
            );
            setData(prepareBarData(responseCurrent.data));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getSalesShops();
    }, [filters]);

    return (
        <Card className={clsx(classes.root)}>
            <CardHeader
                style={{ flexWrap: 'nowrap' }}
                className={classes.cardHeader}
                action={
                    <SalesDetailFilters
                        filters={filters}
                        setFilters={setFilters}
                    />
                }
                title="Godzinowy wykres sprzedaży"
            />
            <Divider />
            <CardContent>
                <Box height={400} position="relative">
                    {data && <Line data={data} options={options} />}
                </Box>
            </CardContent>
        </Card>
    );
};

export default SalesTimeDetail;
