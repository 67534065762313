import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
    Grid,
    TextField,
    Button,
    makeStyles,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import {
    setIsLoading,
    addAlert,
    selectShops,
} from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'context/features/user/userSlice';
import {
    hasDuplicates,
    validateNumberValue,
    filterShopsWithoutEcommerce,
    sortSizes,
} from 'helpers/global';
import { useHistory } from 'react-router-dom';
import { sendAuthAjax } from 'helpers/authorization';
import ConfirmDialog from 'components/ConfirmDialog';
import * as text from 'constants/strings';
import { productType, productGroup } from 'constants/global';
import FormAddSizes from './FormAddSizes';
import type { Shop } from '../helpers/interface';
import { shopName } from 'constants/strings';
import { findShopById } from 'helpers/global';
import useIsGranted from 'hooks/useIsGranted';
import { map } from 'lodash';

const useStyles = makeStyles((theme) => ({
    addToDatabase: {
        margin: theme.spacing(1, 0, 0, 0),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    input: {
        marginRight: '10px',
    },
    table: {
        width: 300,
    },
    addSizeBtn: {
        maxHeight: 40,
    },
    checkStocksCheckbox: {
        marginTop: '20px',
    },
}));

const FormEditShoes = ({
    shoesData,
    isNew,
    submitAction,
    shopId,
    setShopId,
    isOnErrorsPage,
}: any) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [sizes, setSizes] = useState(sortSizes(shoesData.sizes));
    const [dialogOpen, setDialogOpen] = useState(false);
    const [confirmDialogProduct, setConfirmDialogProduct] = useState(null);
    const user = useSelector((state) => selectUser(state));
    const shops = useSelector((state) => selectShops(state));
    const history = useHistory();
    const usersShop = findShopById(shops, user.shopId);
    const formRef = {
        code: useRef(null),
        brand: useRef(null),
        color: useRef(null),
        type: useRef(null),
        normalPrice: useRef(null),
        promotionalPrice: useRef(null),
        addSizes: useRef(null),
        productType: useRef(null),
        group: useRef(null),
    };
    const isGranted = useIsGranted();

    const [
        checkIfSizesChangedInMeantime,
        setCheckIfSizesChangedInMeantime,
    ] = useState(isOnErrorsPage);
    const handleCheckIfSizesChangedInMeantimeCheckboxChange = (
        event: Event
    ) => {
        setCheckIfSizesChangedInMeantime(event.target.checked);
    };

    const isAdminOrManager = isGranted.hasOneOfRoles.execute([
        text.ROLE_ADMIN_CODE,
        text.ROLE_MANAGER_CODE,
    ]);

    const hasAtLeastDirectorRole =
        isAdminOrManager ||
        isGranted.hasOneOfRoles.execute([text.ROLE_DIRECTOR_CODE]);

    const addError = (error: string) => {
        dispatch(addAlert({ content: error, type: 'error' }));
    };

    const validateForm = (form: any) => {
        const formValue = form.target.elements;

        if (formValue.brand.value.trim().length === 0) {
            addError('Błędne dane w polu Marka');
            return false;
        }

        if (formValue.type.value.trim().length === 0) {
            addError('Błędne dane w polu Model');
            return false;
        }

        return true;
    };

    const clearForm = () => {
        formRef.code.current.value = '';
        formRef.brand.current.value = '';
        formRef.color.current.value = '';
        formRef.type.current.value = '';
        formRef.normalPrice.current.value = '';
        formRef.promotionalPrice.current.value = '';
        formRef.productType.current.value = '';
        formRef.group.current.value = '';
        setSizes([]);
        formRef.addSizes.current.clearForm();
    };

    const saveInDb = async () => {
        const sizeInputVal = document.getElementById('size-input').value;
        const quantityInputVal = document.getElementById('quantity-input')
            .value;
        const payload = {
            brand: formRef.brand.current.value,
            code: formRef.code.current.value,
            color: formRef.color.current.value,
            type: formRef.type.current.value,
            primary_price: formRef.normalPrice.current.value,
            promotional_price: formRef.promotionalPrice.current.value,
            productType: formRef.productType.current.value,
            shopId,
            group: formRef.group.current.value,
            sizes:
                sizes.length > 0
                    ? sizes
                    : [
                          {
                              size: parseInt(sizeInputVal, 10),
                              quantity: parseInt(quantityInputVal, 10),
                          },
                      ],
            sizesBeforeEdition: shoesData.sizes,
            checkIfSizesChangedInMeantime,
        };

        dispatch(setIsLoading(true));
        try {
            await submitAction(payload);
            dispatch(
                addAlert({
                    content: isNew
                        ? 'Dodano obuwie do bazy'
                        : 'Poprawiono błędy',
                    type: 'success',
                })
            );

            if (!isNew) {
                history.push('/bledy');
            } else {
                clearForm();
            }
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const openModal = (product: object) => {
        setConfirmDialogProduct(product);
        setDialogOpen(true);
    };

    const checkIfShoesExists = async (brand: string) => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/products/exists/${shopId}/${brand}`
            );

            if (
                response &&
                response.data &&
                response.data.products &&
                response.data.products.length
            ) {
                openModal(response.data.products[0]);
            } else {
                saveInDb();
            }
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const sizeInputVal = document.getElementById('size-input').value;
        const quantityInputVal = document.getElementById('quantity-input')
            .value;

        if (
            !isOnErrorsPage &&
            sizes.length === 0 &&
            (!validateNumberValue(sizeInputVal) ||
                !validateNumberValue(quantityInputVal))
        ) {
            dispatch(
                addAlert({ content: 'Niekompletne rozmiary', type: 'error' })
            );
            return;
        }

        if (hasDuplicates(sizes.reduce((p, c) => p.concat(c.size), []))) {
            dispatch(
                addAlert({ content: 'Zdublikowane rozmiary', type: 'error' })
            );
            return;
        }

        if (!validateForm(e)) {
            return;
        }

        if (isNew) {
            checkIfShoesExists(e.target.elements.brand.value);
        } else {
            saveInDb();
        }
    };

    const handleConfirmCancel = () => {
        setConfirmDialogProduct(null);
        setDialogOpen(false);
        saveInDb();
    };

    const handleConfirmOk = () => {
        setDialogOpen(false);
        history.push(`/bledy/${confirmDialogProduct.id}`);
    };

    const createProductTitle = (product: object) => {
        if (product) {
            return [
                `Czy chodziło Ci o: ${product.brand} ${product.code} ${product.color} ${product.type}`,
            ];
        }
        return [''];
    };

    useEffect(() => {
        setSizes(shoesData.sizes);
        formRef.code.current.focus();
    }, [shoesData]);

    const getShops = () => {
        return shops.filter(
            (shop) =>
                shop.name !== shopName.ECOMMERCE &&
                (isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                ]) ||
                    user.shopId === shop.id ||
                    (isGranted.hasOneOfRoles.execute([
                        text.ROLE_DIRECTOR_CODE,
                    ]) &&
                        usersShop.name === shopName.PRZEJSCIE &&
                        shop.name === shopName.RYNEK))
        );
    };

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Container component="main">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                                Sklep
                            </InputLabel>
                            <Select
                                label="Sklep"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={shopId}
                                onChange={(e) => setShopId(e.target.value)}
                            >
                                {map(getShops(), (shop) => (
                                    <MenuItem key={shop.id} value={shop.id}>
                                        {shop.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            type="number"
                            variant="outlined"
                            name="code"
                            label="Kod"
                            size="small"
                            defaultValue={shoesData.code}
                            inputRef={formRef.code}
                            disabled={!isAdminOrManager && isOnErrorsPage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="productType">
                                Rodzaj produktu
                            </InputLabel>
                            <Select
                                name="productType"
                                label="Rodzaj produktu"
                                size="small"
                                defaultValue={shoesData.product_type || 'shoe'}
                                inputRef={formRef.productType}
                                disabled={!isAdminOrManager && isOnErrorsPage}
                            >
                                {map(productType, (value, key) => (
                                    <MenuItem key={key} value={key}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            variant="outlined"
                            name="brand"
                            label="Marka"
                            size="small"
                            defaultValue={shoesData.brand}
                            inputRef={formRef.brand}
                            disabled={!isAdminOrManager && isOnErrorsPage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="color"
                            label="Kolor"
                            size="small"
                            defaultValue={shoesData.color}
                            inputRef={formRef.color}
                            disabled={!isAdminOrManager && isOnErrorsPage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            variant="outlined"
                            name="type"
                            label="Model"
                            size="small"
                            defaultValue={shoesData.type}
                            inputRef={formRef.type}
                            disabled={!isAdminOrManager && isOnErrorsPage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="group">Grupa produktu</InputLabel>
                            <Select
                                name="group"
                                label="Grupa produktu"
                                size="small"
                                required
                                inputRef={formRef.group}
                                defaultValue={shoesData.group || 'DAMSKIE'}
                                disabled={
                                    !hasAtLeastDirectorRole && isOnErrorsPage
                                }
                            >
                                {map(productGroup, (value, key) => (
                                    <MenuItem key={key} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            fullWidth
                            required
                            type="number"
                            variant="outlined"
                            name="primary_price"
                            label="Cena normalna"
                            size="small"
                            defaultValue={shoesData.primary_price}
                            inputRef={formRef.normalPrice}
                            disabled={!isAdminOrManager && isOnErrorsPage}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="promotional_price"
                            type="number"
                            label="Cena promocyjna"
                            size="small"
                            defaultValue={shoesData.promotional_price}
                            inputRef={formRef.promotionalPrice}
                            disabled={!isAdminOrManager && isOnErrorsPage}
                        />
                    </Grid>
                </Grid>
                <FormAddSizes
                    sizes={sizes}
                    setSizes={setSizes}
                    isAdditionalStandard={isNew}
                    ref={formRef.addSizes}
                />
                <Button
                    className={classes.addToDatabase}
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    {isNew ? 'Dodaj do bazy' : 'Popraw'}
                </Button>
                {isOnErrorsPage && (
                    <Grid item xs={12} className={classes.checkStocksCheckbox}>
                        <FormControl size="small" variant="outlined" fullWidth>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkIfSizesChangedInMeantime}
                                        onChange={
                                            handleCheckIfSizesChangedInMeantimeCheckboxChange
                                        }
                                    />
                                }
                                label={text.CHECK_IF_STOCKS_CHANGED_IN_MEANTIME}
                            />
                        </FormControl>
                    </Grid>
                )}

                <ConfirmDialog
                    open={dialogOpen}
                    title="Potwierdź operację"
                    content={createProductTitle(confirmDialogProduct)}
                    handleCancel={handleConfirmCancel}
                    handleOk={handleConfirmOk}
                    cancelLabel="Nie"
                    okLabel="Tak"
                />
            </Container>
        </form>
    );
};

FormEditShoes.defaultProps = {
    isNew: false,
    shoesData: {
        brand: '',
        model: '',
        color: '',
        type: '',
        normalPrice: '',
        promotionalPrice: '',
        productType: '',
        group: '',
        sizes: [],
    },
    isOnErrorsPage: false,
};

export default FormEditShoes;
