// @flow
import React from 'react';
import {
    Card,
    CardContent,
    Divider,
    Typography,
    makeStyles,
} from '@material-ui/core';
import ChecklistCardItem from './ChecklistCardItem';
import { ChecklistCategory as ChecklistCategoryInterface, ChecklistItem as ChecklistItemInterface } from 'helpers/interface';
import { Control } from 'react-hook-form';
import { Modes } from 'pages/ChecklistCreate';
import { map, sortBy } from 'lodash';

const useStyles = makeStyles(() => ({
    checklistCategory: {
        marginBottom: '10px',
    },
    divider: {
        margin: '10px 0',
    },
}));

type Props = {
    category: ChecklistCategoryInterface;
    control: Control;
    mode: Modes;
};

const ChecklistCategory = ({
    category,
    control,
    mode
}: Props) => {
    const classes = useStyles();

    return (
        <Card className={classes.checklistCategory}>
            <CardContent>
                <Typography color="textSecondary" gutterBottom variant="h6">
                    {category.name}
                </Typography>
                {map(sortBy(category.checklist_items, ['position']), (item: ChecklistItemInterface, index: number, array: ChecklistItemInterface[]) => (
                    <div key={item.id}>
                        <ChecklistCardItem
                            categoryId={category.id}
                            item={item}
                            control={control}
                            mode={mode}
                        />
                        {index < array.length - 1 && (
                            <Divider className={classes.divider} />
                        )}
                    </div>
                ))}
            </CardContent>
        </Card>
    );
};

export default ChecklistCategory;

