// @flow
import React, { useState, useRef } from 'react';
import { TableRow, TableCell, TextField, Button } from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import SearchBar from 'components/SearchBar';
import { useDispatch } from 'react-redux';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import SaleTableWithPagination from 'components/SaleTableWithPagination';

const Row = ({ row }: { row: any }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState({
        primary_price: '',
        promotional_price: '',
    });
    const priceRef = useRef(null);
    const [allowSendRequest, setAllowSendRequest] = useState(true);

    const handleOnClick = async () => {
        const { primary_price, promotional_price } = data;

        if (primary_price === '' || promotional_price === '') {
            dispatch(
                addAlert({
                    content: 'Ceny nie mogą być pustę',
                    type: 'error',
                })
            );
            return;
        }

        if (!allowSendRequest) {
            return;
        }
        setAllowSendRequest(false);

        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('put', `/products/${row.id}/prices`, {
                primary_price,
                promotional_price,
            });
            priceRef.current.innerText = `${data.primary_price}zł / ${data.promotional_price}zł`;
            setData({
                primary_price: '',
                promotional_price: '',
            });
            dispatch(addAlert({ content: 'Zmieniono cenę', type: 'success' }));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
        setAllowSendRequest(true);
    };

    return (
        <TableRow hover key={row.id}>
            <TableCell align="center">{row.code}</TableCell>
            <TableCell align="center">{row.brand}</TableCell>
            <TableCell align="center">{row.color}</TableCell>
            <TableCell align="center">{row.type}</TableCell>
            <TableCell align="center" ref={priceRef}>
                {`${row.primary_price}zł / ${row.promotional_price}zł`}
            </TableCell>
            <TableCell align="center">
                <TextField
                    label="cena normalna"
                    type="number"
                    value={data.primary_price}
                    onChange={(e) =>
                        setData({ ...data, primary_price: e.target.value })
                    }
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    label="cena promocyjna"
                    type="number"
                    value={data.promotional_price}
                    onChange={(e) =>
                        setData({ ...data, promotional_price: e.target.value })
                    }
                />
            </TableCell>
            <TableCell align="center">
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => handleOnClick(row.id)}
                >
                    Zmień cene
                </Button>
            </TableCell>
        </TableRow>
    );
};

const PriceChange = () => {
    const [result, setResult] = useState([]);

    return (
        <ViewContentCard title={text.PRICE_CHANGE}>
            <SearchBar setResult={setResult} />
            {result.length > 0 && (
                <SaleTableWithPagination
                    headers={[
                        text.CODE,
                        text.BRAND,
                        text.COLOR,
                        text.MODEL,
                        text.ACTUAL_PRICE,
                        text.NORMAL_PRICE,
                        text.PROMOTIONAL_PRICE,
                        '',
                    ]}
                    rowRender={(row) => <Row row={row} key={row.id} />}
                    rowsData={result}
                />
            )}
        </ViewContentCard>
    );
};

export default PriceChange;
