import React from 'react';
import { MonthSelection } from '@material-ui/pickers/views/Month/MonthView';

export const CustomMonth = ({
    month,
    selectedMonth,
    ...props
}: {
    month: MaterialUiPickersDate,
    selectedMonth: Array<string>,
}) => {
    const hasAddition = selectedMonth.includes(month.format('Y-MM'));

    return (
        <MonthSelection
            {...props}
            style={{
                color: hasAddition && 'red',
                textDecoration: hasAddition && 'underline',
            }}
        >
            {month.format('M')}
        </MonthSelection>
    );
};

export default CustomMonth;
