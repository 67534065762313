import { AxiosError } from 'axios';
import { sendAuthAjax } from 'helpers/authorization';
import { Addition, Attendance, Pagination } from 'helpers/interface';
import { useQuery, UseQueryOptions } from 'react-query';

export const key = 'addition';

type Params = {
    url: string;
};

const getAddition = async ({ url }: Params) => {
    const { data } = await sendAuthAjax('get', url, undefined);

    return data;
};

export default function useAdditionQuery(
    params: Params,
    options: UseQueryOptions<Addition[], AxiosError> = {}
) {
    return useQuery<Addition[], AxiosError>(
        [key, params],
        () => getAddition(params),
        options
    );
}
