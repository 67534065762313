import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { shopShortName } from 'constants/strings';
import { addAlert, selectShops } from 'context/features/global/globalSlice';
import { Attendance, Shop } from 'helpers/interface';
import useAddAttendanceMutation from 'hooks/useAddAttendanceMutation';
import { key } from 'hooks/useAttendancesQuery';
import useUpdateAttendanceMutation from 'hooks/useUpdateAttendanceMutation';
import { filter, includes, map } from 'lodash';
import moment, { Moment } from 'moment';
import React, { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

type FormInput = {
    id: number;
    firstName: string;
    lastName: string;
    date: Moment;
    description: string;
    shops: number[];
};

type Props = {
    onAdd: () => void;
    attendance: Attendance | null;
};

function AttendanceDialogContent({ onAdd, attendance }: Props) {
    const shopList = useSelector((state) => selectShops(state)) as Shop[];
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { control, handleSubmit, reset } = useForm<FormInput>({
        defaultValues: {
            id: 0,
            firstName: '',
            lastName: '',
            date: moment(),
            description: '',
            shops: [],
        },
    });

    useEffect(() => {
        reset({
            id: attendance?.id || 0,
            firstName: attendance?.firstName || '',
            lastName: attendance?.lastName || '',
            date: attendance ? moment(attendance?.date) : moment(),
            description: attendance?.description || '',
            shops: map(attendance?.shops, 'shopId'),
        });
    }, [attendance]);

    const { mutate: addAttendanceMutation } = useAddAttendanceMutation();
    const { mutate: updateAttendanceMutation } = useUpdateAttendanceMutation();

    const isNew = !attendance?.id;

    console.log(shopList);

    const handleSave = async () => {
        handleSubmit((data) => {
            const mutate = isNew
                ? addAttendanceMutation
                : updateAttendanceMutation;

            mutate(
                {
                    ...data,
                    date: data?.date?.format('YYYY-MM-DD'),
                },
                {
                    onSuccess: () => {
                        dispatch(
                            addAlert({
                                content: isNew
                                    ? 'Poprawnie dodano'
                                    : 'Poprawnie zaktualizowano',
                                type: 'success',
                            })
                        );

                        queryClient.invalidateQueries([key]);

                        onAdd();
                    },
                    onError: (err) => {
                        const errorText = err?.response?.data?.message
                            ? err?.response?.data?.message
                            : err?.response?.statusText;

                        dispatch(
                            addAlert({ content: errorText, type: 'error' })
                        );
                    },
                }
            );
        })();
    };

    return (
        <>
            <DialogTitle>
                {isNew
                    ? 'Dodawanie nowej pozycji do listy obecności'
                    : 'Edycja pozycji na liście obecności'}
            </DialogTitle>
            <DialogContent>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            rules={{
                                required: 'To pole jest wymagane',
                                maxLength: {
                                    value: 20,
                                    message:
                                        'Maksymalna długość wynosi 20 znaków',
                                },
                            }}
                            name="firstName"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    required
                                    helperText={error?.message}
                                    error={!!error?.message}
                                    fullWidth
                                    size="small"
                                    label="Imię"
                                    variant="standard"
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            rules={{
                                required: 'To pole jest wymagane',
                                maxLength: {
                                    value: 20,
                                    message:
                                        'Maksymalna długość wynosi 20 znaków',
                                },
                            }}
                            name="lastName"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    required
                                    helperText={error?.message}
                                    error={!!error?.message}
                                    fullWidth
                                    size="small"
                                    label="Nazwisko"
                                    variant="standard"
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            rules={{ required: 'To pole jest wymagane' }}
                            name="date"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <KeyboardDatePicker
                                    helperText={error?.message}
                                    error={!!error?.message}
                                    label="Data"
                                    required
                                    fullWidth
                                    size="small"
                                    variant="dialog"
                                    format="YYYY-MM-DD"
                                    inputVariant="standard"
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            rules={{ required: 'To pole jest wymagane' }}
                            name="shops"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl
                                    fullWidth
                                    error={!!error?.message}
                                    required
                                >
                                    <InputLabel
                                        id="shop-label"
                                        style={{ width: '100%' }}
                                    >
                                        Sklep
                                    </InputLabel>
                                    <Select
                                        labelId="shop-label"
                                        multiple
                                        input={<Input />}
                                        {...field}
                                    >
                                        {map(
                                            filter(shopList, (shop) =>
                                                includes(
                                                    [
                                                        shopShortName.MAGAZYN,
                                                        shopShortName.RYNEK,
                                                        shopShortName.PRZEJSCIE,
                                                        shopShortName.SUKIENNICE,
                                                        shopShortName.KRUPOWKI,
                                                        shopShortName.KARPACZ,
                                                        shopShortName.KARPACZ2,
                                                    ],
                                                    shop.short
                                                )
                                            ),
                                            (shop) => (
                                                <MenuItem
                                                    key={shop.id}
                                                    value={shop.id}
                                                >
                                                    {shop.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>

                                    {error?.message && (
                                        <FormHelperText
                                            error={!!error?.message}
                                        >
                                            {error?.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            rules={{
                                maxLength: {
                                    value: 255,
                                    message:
                                        'Maksymalna długość wynosi 255 znaków',
                                },
                            }}
                            name="description"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    helperText={error?.message}
                                    error={!!error?.message}
                                    label="Adnotacje"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                >
                    {isNew ? 'Dodaj' : 'Edytuj'}
                </Button>
            </DialogActions>
        </>
    );
}

export default memo(AttendanceDialogContent);
