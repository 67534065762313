// @flow
import React, { useEffect, useState } from 'react';
import * as text from 'constants/strings';
import ViewContentCard from 'components/Layout/ViewContentCard';

import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CustomDay from 'components/CustomDay';
import {
    addAlert,
    selectShops,
    setIsLoading,
} from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { sendAuthAjax } from 'helpers/authorization';
import moment from 'moment';
import CollapsedTable from 'components/CollapsedTable';
import type { Shop } from "../helpers/interface";
import { filterShopsWithoutEcommerce } from "../helpers/global";

const useStyles = makeStyles({
    root: {
        marginTop: '8px',
    },
    formControl: {
        margin: `8px 0px`,
    },
});

const StatementAddition = () => {
    const classes = useStyles();
    const [date, setDate] = useState(new Date());
    const [selectedShop, setSelectedShop] = useState('');
    const [additionDays, setAdditionDays] = useState([]);
    const [result, setResult] = useState({
        details: [],
        total: { sum: 0, sizes: [] },
    });
    const shops = useSelector((state) => selectShops(state));
    const dispatch = useDispatch();

    const getAdditionalDays = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/statement/addition-days?shopId=${selectedShop}`
            );
            setAdditionDays(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const getAdditions = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/statement/addition?shopId=${selectedShop}&date=${moment(
                    date
                ).format('YYYY-MM-DD')}`
            );
            setResult(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickSearch = () => {
        if (!moment(date).isValid()) {
            dispatch(
                addAlert({
                    content: 'Nieprawidłowy przedział czasowy',
                    type: 'error',
                })
            );
            return;
        }
        getAdditions();
    };

    useEffect(() => {
        getAdditionalDays();
    }, [selectedShop]);

    return (
        <ViewContentCard title={text.STATEMENT_ADDITION}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid
                    className={classes.root}
                    container
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={12} md={4} lg={2}>
                        <FormControl
                            className={classes.formControl}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                        >
                            <InputLabel shrink id="content-select-label">
                                Sklep
                            </InputLabel>
                            <Select
                                labelId="content-select-label"
                                value={selectedShop}
                                label="Sklep"
                                displayEmpty
                                onChange={(e) =>
                                    setSelectedShop(e.target.value)
                                }
                            >
                                <MenuItem value="">Wszystko</MenuItem>
                                {filterShopsWithoutEcommerce(shops, (shop: Shop) => (
                                    <MenuItem key={shop.id} value={shop.id}>
                                        {shop.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                        <KeyboardDatePicker
                            fullWidth
                            label="Data"
                            size="small"
                            format="YYYY-MM-DD"
                            variant="inline"
                            inputVariant="outlined"
                            value={date}
                            onChange={(e) => setDate(e)}
                            renderDay={(
                                day,
                                selectedDate,
                                dayInCurrentMonth,
                                dayComponent
                            ) => (
                                <CustomDay
                                    additionDays={additionDays}
                                    {...dayComponent.props}
                                    day={day}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={1}>
                        <Button
                            fullWidth
                            onClick={handleClickSearch}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            szukaj
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <CollapsedTable
                            title="Stan magazynowy"
                            details={result.details}
                            total={result.total.sum}
                            totalSizes={result.total.sizes}
                            hasAddColumns
                            hasClickableRow={true}
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </ViewContentCard>
    );
};

export default StatementAddition;
