// @flow
import * as text from 'constants/strings';
import useIsGranted from './useIsGranted';

const useMenuList = () => {
    const isGranted = useIsGranted();

    return [
        {
            id: 'MENU_SALES_PREVIEW',
            name: text.SALES_PREVIEW,
            path: '/podglad-sprzedazy',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
            shortMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
            ]),
        },
        {
            id: 'MENU_SALE',
            name: text.SALE,
            path: '/sprzedaz',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
            shortMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
            ]),
            shopRequired: true,
        },
        {
            id: 'MENU_FIND_SHOES',
            name: text.FIND_SHOES,
            path: '/znajdz-buty',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
                text.ROLE_WAREHOUSE_WORKER_CODE,
            ]),
            shortMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
            ]),
        },
        {
            id: 'MENU_ATTENDANCE_LIST',
            name: text.ATTENDANCE_LIST,
            path: '/lista-obecnosci',
            sideMenuVisible: true,
        },
        {
            id: 'MENU_TRANSFER_OF_SHOES_TO',
            name: text.TRANSFER_OF_SHOES_TO,
            path: '/przerzut',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_WAREHOUSE_WORKER_CODE,
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
            shortMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
            ]),
            shopRequired: true,
        },
        {
            id: 'MENU_SHOE_REPLACEMENT',
            name: text.SHOE_REPLACEMENT,
            path: '/wymiana-butow',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_ADMIN_CODE,
            ]),
            shortMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
            ]),
            shopRequired: true,
        },
        {
            id: 'MENU_SHOE_RELEASE',
            name: text.SHOE_RELEASE,
            path: '/wydanie',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
            shopRequired: true,
        },
        {
            id: 'MENU_ADDING_SHOES',
            name: text.ADDING_SHOES,
            path: '/dodanie-butow',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_WAREHOUSE_WORKER_CODE,
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_ADDING_SIZES',
            name: text.ADDING_SIZES,
            path: '/dodanie-rozmiarow',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_WAREHOUSE_WORKER_CODE,
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_BIG_TRASFER_WITH',
            name: text.BIG_TRASFER_WITH,
            path: '/duze-przerzuty',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_WAREHOUSE_WORKER_CODE,
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
            shopRequired: true,
        },
        {
            id: 'MENU_ADDITION',
            name: text.ADDITION,
            path: '/dolozenie/zakopane',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_WAREHOUSE_WORKER_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
            ]),
            shortMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_WAREHOUSE_WORKER_CODE,
            ]),
        },
        {
            id: 'MENU_STATEMENT_TOTAL',
            name: text.STATEMENT_TOTAL,
            path: '/calkowite-zestawienie-sprzedazy',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
            ]),
        },
        {
            id: 'MENU_STATEMENT_SHOP',
            name: text.STATEMENT_SHOP,
            path: '/zestawienie-sprzedazy-z-poszczegolnych-sklepow',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
                text.ROLE_EMPLOYEE_CODE,
            ]),
        },
        {
            id: 'MENU_STATEMENT_PRODUCT',
            name: text.STATEMENT_PRODUCT,
            path: '/historia-poszczegolnego-modelu',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
            ]),
        },
        {
            id: 'MENU_STATEMENT_ADDITION',
            name: text.STATEMENT_ADDITION,
            path: '/podglad-dodan',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
            ]),
        },
        {
            id: 'MENU_REPORTS',
            name: text.REPORTS,
            path: '/raporty',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_SHORTAGES',
            name: text.SHORTAGES,
            path: '/braki',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_LOGS',
            name: text.LOGS,
            path: '/logi',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
            ]),
        },
        {
            id: 'MENU_STATEMENT_EXCHANGE',
            name: text.STATEMENT_EXCHANGE,
            path: '/obuwie-wydane-dla-pracownikow',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_ADDING_MESSAGES',
            name: text.MESSAGES,
            path: '/komunikaty',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_WAREHOUSE_WORKER_CODE,
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_PRICE_CHANGE',
            name: text.PRICE_CHANGE,
            path: '/zmiana-ceny',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_GRAPHIC',
            name: text.GRAPHIC,
            path: '/grafik',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
                text.ROLE_WAREHOUSE_WORKER_CODE,
            ]),
        },
        {
            id: 'MENU_COMPLAINTS',
            name: text.COMPLAINTS,
            path: '/reklamacje',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_INVOICES',
            name: text.INVOICES,
            path: '/faktury',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_ERRORS',
            name: text.ERRORS,
            path: '/bledy',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
                text.ROLE_WAREHOUSE_WORKER_CODE,
            ]),
        },
        {
            id: 'MENU_EXHIBITION',
            name: text.EXHIBITION,
            path: '/wystawa',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_LABELS',
            name: text.LABELS,
            path: '/etykiety',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_DICTIONARIES',
            name: text.DICTIONARIES,
            path: '/slowniki',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
            ]),
        },
        {
            id: 'MENU_WAREHOUSE',
            name: text.WAREHOUSE,
            path: '/magazyn',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_EMPLOYEE_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_WORKERS',
            name: text.WORKERS,
            path: '/pracownicy',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
            ]),
        },
        {
            id: 'MENU_PRODUCT_OF_THE_DAY',
            name: text.PRODUCT_OF_THE_DAY,
            path: '/produkt-dnia',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_ADMIN_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_DIRECTOR_CODE,
                text.ROLE_EMPLOYEE_CODE,
            ]),
        },
        {
            id: 'MENU_CHECKLIST',
            name: text.CHECKLIST,
            path: '/checklista',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_ADMIN_CODE,
                text.ROLE_MANAGER_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_COUPONS',
            name: text.COUPONS,
            path: '/kupony',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
                text.ROLE_DIRECTOR_CODE,
            ]),
        },
        {
            id: 'MENU_APP_SETTINGS',
            name: text.APP_SETTINGS,
            path: '/ustawienia',
            sideMenuVisible: isGranted.hasOneOfRoles.execute([
                text.ROLE_MANAGER_CODE,
                text.ROLE_ADMIN_CODE,
            ]),
        },
        {
            id: 'MENU_APP_SHOPER_CONFIG',
            name: text.APP_SHOPER_CONFIG,
            path: '/shoper-konfiguracja',
            sideMenuVisible: isGranted.canSeeShoperConfiguration.execute(),
        },
    ];
};

export default useMenuList;
