// @ts-nocheck
import { useSelector } from 'react-redux';
import { selectUser } from 'context/features/user/userSlice';
import { selectShops } from 'context/features/global/globalSlice';
import { findShopById } from 'helpers/global';
import * as text from 'constants/strings';
import { some, includes } from 'lodash';
import { Shop } from 'constants/dictionaries';

const useIsGranted = () => {
    const user = useSelector(selectUser);
    const shops = useSelector(selectShops);
    const usersShop = findShopById(shops, user.shopId);

    const hasOneOfRoles = {
        execute: (roles: Array<string>) =>
            some(user.roles, (r) => includes(roles, r)),
    };

    const canSeeShoperConfiguration = {
        execute: () => {
            if (
                hasOneOfRoles.execute([text.ROLE_MANAGER_CODE]) ||
                usersShop?.name === Shop.Poraj ||
                usersShop?.name === Shop.Bialka ||
                usersShop?.name === 'Sprzedaż Internetowa'
            ) {
                return true;
            }
            return false;
        },
    };

    const canAddProductsOfTheDay = {
        execute: () => {
            return hasOneOfRoles.execute([text.ROLE_MANAGER_CODE, text.ROLE_ADMIN_CODE]) || 
                (hasOneOfRoles.execute([text.ROLE_DIRECTOR_CODE]) && usersShop?.name === Shop.Poraj)
            ;
        },
    };

    return {
        hasOneOfRoles,
        canSeeShoperConfiguration,
        canAddProductsOfTheDay,
    };
};

export default useIsGranted;
