// @flow
import React from 'react';
import { Day } from '@material-ui/pickers';

export const CustomDay = ({
    day,
    additionDays,
    ...props
}: {
    day: MaterialUiPickersDate,
    additionDays: Array<string>,
}) => {
    const hasAddition = additionDays.includes(day.format('Y-MM-DD'));

    return (
        <Day
            {...props}
            style={{
                color: hasAddition && 'red',
                textDecoration: hasAddition && 'underline',
            }}
        >
            {day.format('D')}
        </Day>
    );
};

export default CustomDay;
