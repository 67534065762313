import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import TableLoader from 'components/TableLoader';
import * as text from 'constants/strings';
import { Attendance, Pagination, Sort } from 'helpers/interface';
import useIsGranted from 'hooks/useIsGranted';
import { join, map } from 'lodash';
import moment from 'moment';
import React, { memo } from 'react';
import { StyledTableCell } from 'styles/components';

type AttendanceTableProps = {
    loading: boolean;
    data: Pagination<Attendance[]> | undefined;
    setSort: React.Dispatch<React.SetStateAction<Sort | null>>;
    sort: Sort | null;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    page: number;
    pageSize: number;
    onEdit?: (row: Attendance) => void;
    onRemove?: (id: number) => void;
};

function AttendanceTable({
    loading,
    data,
    setSort,
    sort,
    setPage,
    page,
    pageSize,
    onEdit,
    onRemove,
}: AttendanceTableProps) {
    const isGranted = useIsGranted();
    const canEditAndDelete = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_DIRECTOR_CODE,
    ]);

    return (
        <TableContainer component={Paper} style={{ position: 'relative' }}>
            {loading && <TableLoader />}
            <Table>
                <TableHead>
                    <TableRow style={{ overflow: 'hidden' }}>
                        {canEditAndDelete && (
                            <StyledTableCell>Usuń</StyledTableCell>
                        )}
                        <StyledTableCell>Lp.</StyledTableCell>
                        <StyledTableCell>Imię i Nazwisko</StyledTableCell>

                        <StyledTableCell align="center">
                            <TableSortLabel
                                active={sort !== null}
                                direction={sort?.direction}
                                onClick={() => {
                                    setSort(
                                        sort?.direction === 'desc'
                                            ? null
                                            : {
                                                  key: 'date',
                                                  direction:
                                                      sort === null
                                                          ? 'asc'
                                                          : 'desc',
                                              }
                                    );
                                }}
                            >
                                Data
                            </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell>Sklep</StyledTableCell>
                        <StyledTableCell>Adnotacje</StyledTableCell>
                        {canEditAndDelete && (
                            <StyledTableCell></StyledTableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {map(data?.data, (item, index) => (
                        <TableRow key={item.id}>
                            {canEditAndDelete && (
                                <TableCell align="center" width={50}>
                                    <IconButton
                                        onClick={() => {
                                            onRemove?.(item.id);
                                        }}
                                    >
                                        <DeleteOutlineIcon color="error" />
                                    </IconButton>
                                </TableCell>
                            )}
                            <TableCell align="center" width={50}>
                                {index + 1}
                            </TableCell>
                            <TableCell align="center" width={450}>
                                {item?.firstName} {item?.lastName}
                            </TableCell>
                            <TableCell align="center" width={150}>
                                {moment(item.date).format('YYYY-MM-DD')}
                            </TableCell>
                            <TableCell align="center" width={350}>
                                {join(map(item?.shops, 'name'), ', ')}
                            </TableCell>
                            <TableCell align="center">
                                {item?.description}
                            </TableCell>
                            {canEditAndDelete && (
                                <TableCell align="center" width={50}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            console.log(item, 'item');
                                            onEdit?.(item);
                                        }}
                                    >
                                        Edytuj
                                    </Button>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} z ${count}`
                        }
                        count={data?.totalCount || 0}
                        rowsPerPage={pageSize}
                        page={page}
                        onChangePage={(_, page) => {
                            setPage(page);
                        }}
                    />
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

export default memo(AttendanceTable);
