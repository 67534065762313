// @flow
import React from 'react';
import styled from 'styled-components/macro';
import { selectShop } from 'context/features/global/globalSlice';
import { useSelector } from 'react-redux';

const Text = styled.span`
    color: ${({ fontcolor }) => fontcolor};
`;

type Props = {
    shopId: number,
    children: any,
};

const ColoringShop = ({ shopId, children }: Props) => {
    const shop = useSelector((state) => selectShop(shopId)(state));

    return <Text fontcolor={(shop ? shop.color : 'black')}>{children}</Text>;
};

export default ColoringShop;
