// @flow
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    TableCell,
    TableRow,
    Switch,
    FormControlLabel,
    Paper,
    makeStyles,
    TableContainer,
    TableBody,
    Table,
    TableHead,
    colors,
} from '@material-ui/core';
import * as text from 'constants/strings';
import 'moment/locale/pl';
import { StyledTableCell } from 'styles/components';
import clsx from 'clsx';
import { findColorShopByName } from 'helpers/global';
import { selectShopsWithoutWarehouse } from 'context/features/global/globalSlice';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    paper: {
        padding: '10px',
        margin: '10px 0px',
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    totalRow: {
        backgroundColor: colors.green[50],
    },
    highlightedValue: {
        fontWeight: 800,
    },
    emptyValue: {
        color: 'red',
        opacity: 0.3,
    },
    tableWithDetail: {
        '& thead th, & tbody td.expanded': {
            padding: '6px',
            textAlign: 'center',
        },
    },
    container: {
        maxHeight: 440,
    },
    clicableRow: {
        cursor: 'pointer'
    },
});

type Props = {
    title: string,
    details: any,
    total: number,
    totalSizes: any,
    hasTotalRow: boolean,
    hasAddColumns: boolean,
    hasClickableRow: boolean,
};

const CollapsedTable = ({
    title,
    details,
    total,
    totalSizes,
    hasTotalRow = true,
    hasAddColumns = false,
    hasClickableRow = false,
}: Props) => {
    const [expandedDetail, setExpandedDetail] = useState(false);
    const classes = useStyles();
    const columns = hasAddColumns
        ? [text.CODE, text.BRAND, text.COLOR, text.MODEL, text.SUM]
        : ['', text.SUM];
    const headers = expandedDetail
        ? [...columns, ...Object.keys(totalSizes)]
        : [...columns];

    const getCell = (label, data) => {
        return data[label] ? (
            <span className={classes.highlightedValue}>{data[label]}</span>
        ) : (
            <span className={classes.emptyValue}>0</span>
        );
    };
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));
    const history = useHistory();

    const handleRowClick = (rowId: number) => {
        history.push(`/historia-poszczegolnego-modelu/${rowId}`);
    };

    return (
        Object.keys(details).length > 0 && (
            <Paper className={classes.paper}>
                <Typography variant="h6">{title}</Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={expandedDetail}
                            onChange={(e) =>
                                setExpandedDetail(e.target.checked)
                            }
                        />
                    }
                    label="rozwiń szczegóły"
                />
                <div className={classes.root}>
                    <TableContainer
                        component={Paper}
                        className={classes.container}
                    >
                        <Table
                            stickyHeader
                            size="small"
                            className={clsx(
                                expandedDetail && classes.tableWithDetail
                            )}
                        >
                            <TableHead>
                                <TableRow>
                                    {headers.map((head) => (
                                        <StyledTableCell key={`head-${head}`}>
                                            {head}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {} 
                                {Object.values(details).map((row) => (
                                   <TableRow 
                                        key={row.name}
                                        onClick={hasClickableRow ? () => handleRowClick(row.productId) : null}
                                        className={hasClickableRow ? classes.clicableRow : ''}
                                    >
                                        {hasAddColumns ? (
                                            <>
                                                <TableCell align="center">
                                                    {row.productCode}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.productBrand}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.productColor}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.productType}
                                                </TableCell>
                                            </>
                                        ) : (
                                            <TableCell
                                                style={{ minWidth: '115px'}}
                                                bgcolor={findColorShopByName(shops, row.name)}
                                            >
                                                {row.name}
                                            </TableCell>
                                        )}
                                        <TableCell align="center">
                                            {row.sum}
                                        </TableCell>
                                        {expandedDetail &&
                                            Object.keys(totalSizes).map(
                                                (label) => (
                                                    <TableCell
                                                        key={`${row.name}-${label}`}
                                                        align="center"
                                                        className="expanded"
                                                    >
                                                        {getCell(
                                                            label,
                                                            row.sizes
                                                        )}
                                                    </TableCell>
                                                )
                                            )}
                                    </TableRow>
                                ))}
                                {hasTotalRow && (
                                    <TableRow className={classes.totalRow}>
                                        <TableCell
                                            colSpan={hasAddColumns ? 4 : 1}
                                        >
                                            Łącznie
                                        </TableCell>
                                        <TableCell align="center">
                                            {total}
                                        </TableCell>
                                        {expandedDetail &&
                                            Object.keys(totalSizes).map(
                                                (label) => (
                                                    <TableCell
                                                        key={`total-${label}`}
                                                        align="center"
                                                        className="expanded"
                                                    >
                                                        {getCell(
                                                            label,
                                                            totalSizes
                                                        )}
                                                    </TableCell>
                                                )
                                            )}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
        )
    );
};

export default CollapsedTable;
