// @flow
import React from 'react';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import WorkerFormEdit from 'components/WorkerFormEdit';
import { useParams } from 'react-router-dom';

const EditWorker = () => {
    const { id } = useParams();

    return (
        <ViewContentCard
            title={id !== undefined ? text.EDIT_WORKER : text.ADDING_WORKER}
        >
            <WorkerFormEdit userId={id} />
        </ViewContentCard>
    );
};

export default EditWorker;
