// @flow
import React from 'react';
import { Snackbar } from '@material-ui/core';
import { selectGlobal, removeAlert } from 'context/features/global/globalSlice';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

const SnackBarAlert = () => {
    const { alert } = useSelector((state) => selectGlobal(state));
    const dispatch = useDispatch();

    const handleOnClose = () => {
        dispatch(removeAlert());
    };

    return (
        alert !== null && (
            <Snackbar
                open
                autoHideDuration={10000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleOnClose}
            >
                <Alert
                    onClose={handleOnClose}
                    severity={alert.type}
                    variant="filled"
                >
                    {alert.content}
                </Alert>
            </Snackbar>
        )
    );
};

export default SnackBarAlert;
