import { AxiosError } from 'axios';
import { sendAuthAjax } from 'helpers/authorization';
import { AttendanceDto, ChecklistReport, ChecklistReportCreateDto } from 'helpers/interface';
import { useMutation, UseMutationOptions } from 'react-query';

const path = '/checklist-reports';

const postChecklistReport = async (payload: ChecklistReport) => {
    const { data } = await sendAuthAjax('post', path, payload);
    return data;
};

export default function useAddChecklistReportMutation(
    options: UseMutationOptions<ChecklistReport, AxiosError, ChecklistReportCreateDto> = {}
) {
    return useMutation<ChecklistReport, AxiosError, ChecklistReportCreateDto>(
        postChecklistReport,
        options
    );
}
