// @flow
import React, { useState, useEffect, useRef } from 'react';
import {
    TableRow,
    TableCell,
    IconButton,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Container,
    makeStyles,
    Paper,
    Chip,
    Input,
    useTheme,
} from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import SaleTable from 'components/SaleTable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/pl';
import {
    selectShops,
    addAlert,
    setIsLoading,
} from 'context/features/global/globalSlice';
import styled from 'styled-components/macro';
import { sendAuthAjax } from 'helpers/authorization';
import clsx from 'clsx';
import red from '@material-ui/core/colors/red';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const CustomChip = styled(Chip)`
    background-color: ${({ bgcolor }) => bgcolor && bgcolor};
    color: white;
    margin-right: 2px;
`;

const useStyles = makeStyles((theme) => ({
    addMessage: {
        margin: theme.spacing(2, 0),
    },
    formControl: {
        margin: theme.spacing(2, 0),
    },
    container: {
        margin: `${theme.spacing(2)}px auto`,
    },
    expired: {
        backgroundColor: red[50],
    },
    contentCell: {
        wordBreak: 'break-all',
        minWidth: '200px',
    },
    chip: {
        margin: '1px',
    },
}));

function getStyles(name, recipientsSelected, theme) {
    return {
        fontWeight:
            recipientsSelected.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const Messages = () => {
    const classes = useStyles();
    const [messages, setMessages] = useState([]);
    const [needs, setNeeds] = useState([]);
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(moment().endOf('day'));
    const [need, setNeed] = useState('');
    const shops = useSelector((state) => selectShops(state));
    const [recipientsSelected, setRecipientsSelected] = useState([]);
    const theme = useTheme();
    const contentInputRef = useRef(null);
    const [newMessageX, setNewMessageX] = useState(null);

    const getRecipients = () => {
        return shops.concat({ id: 'ROLE_MANAGER', name: 'Manager' });
    };

    const getRecipient = (id) => {
        return getRecipients().find((item) => item.id === id);
    };

    const getNeedContent = () => {
        const needItem = needs.find((item) => item.id === need);

        return needItem !== undefined ? needItem.value : '';
    };

    const clearForm = () => {
        contentInputRef.current.value = ''; 
        setNeed([]);
        setSelectedDate(moment().endOf('day'));
        setRecipientsSelected([]);
    }

    const handleClickAddMessage = async () => {
        const content = `${contentInputRef.current.value} ${getNeedContent()}`;

        if (
            (selectedDate !== null && !moment(selectedDate).isValid()) ||
            recipientsSelected.length === 0 ||
            content.trim().length === 0
        ) {
            dispatch(addAlert({ content: 'Niekompletne dane', type: 'error' }));
            return;
        }

        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax('post', '/messages', {
                content,
                endDate:
                    selectedDate !== null
                        ? moment(selectedDate).format('YYYY-MM-DD HH:mm')
                        : null,
                recipients: recipientsSelected,
            });
            dispatch(
                addAlert({
                    content: 'Poprawnie dodano komunikat',
                    type: 'success',
                })
            );
            setNewMessageX(Date.now());
            clearForm();
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickRemoveMessage = async (messageId) => {
        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax('delete', `/messages/${messageId}`);
            dispatch(
                addAlert({
                    content: 'Poprawnie usunięto komunikat',
                    type: 'success',
                })
            );
            setMessages((prev) =>
                prev.filter((message) => message.message_id !== messageId)
            );
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleSubmitEnter = (e) => {
        if (e.key === 'Enter') {
            handleClickAddMessage();
        }
    };

    const getMessages = async () => {
        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthAjax('get', '/messages');

            setMessages(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const getNeeds = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/dictionaries/categories/needs`
            );
            setNeeds(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getMessages();
    }, [newMessageX]);

    useEffect(() => {
        getNeeds();
    }, []);

    useEffect(() => {
        clearForm();
        contentInputRef.current.focus();
    }, []);
    
    return (
        <ViewContentCard title={text.ADDING_MESSAGES}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Container
                    maxWidth={false}
                    className={classes.container}
                    component={Paper}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                className={classes.formControl}
                                inputRef={contentInputRef}
                                label={"Treść"}
                                onKeyDown={handleSubmitEnter}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                fullWidth
                                className={classes.formControl}
                            >
                                <InputLabel shrink id="need-select-label">
                                    Potrzeba
                                </InputLabel>
                                <Select
                                    labelId="need-select-label"
                                    value={need}
                                    onChange={(e) => setNeed(e.target.value)}
                                    displayEmpty
                                >
                                    <MenuItem value="">-------</MenuItem>
                                    {needs.map((shop) => (
                                        <MenuItem key={shop.id} value={shop.id}>
                                            {shop.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <KeyboardDateTimePicker
                                fullWidth
                                className={classes.formControl}
                                variant="dialog"
                                format="YYYY-MM-DD HH:mm"
                                value={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                label="Date ważności"
                                minDate={moment()}
                                ampm={false}
                                clearable
                                clearLabel="Wyczyść"
                                cancelLabel="Anuluj"
                                onKeyDown={handleSubmitEnter}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="recipients-chip-label">
                                    Do kogo
                                </InputLabel>
                                <Select
                                    labelId="recipients-chip-label"
                                    multiple
                                    value={recipientsSelected}
                                    onChange={(e) =>
                                        setRecipientsSelected(e.target.value)
                                    }
                                    input={<Input />}
                                    renderValue={(selected) => (
                                        <div className={classes.chips}>
                                            {selected.map((value) => {
                                                const recipient = getRecipient(
                                                    value
                                                );

                                                return (
                                                    <CustomChip
                                                        key={value}
                                                        label={recipient.name}
                                                        className={classes.chip}
                                                        bgcolor={
                                                            recipient.color
                                                        }
                                                    />
                                                );
                                            })}
                                        </div>
                                    )}
                                >
                                    {getRecipients().map((recipient) => (
                                        <MenuItem
                                            key={recipient.id}
                                            value={recipient.id}
                                            style={getStyles(
                                                recipient.name,
                                                recipientsSelected,
                                                theme
                                            )}
                                        >
                                            {recipient.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                fullWidth
                                className={classes.addMessage}
                                onClick={handleClickAddMessage}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Dodaj komunikat
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </MuiPickersUtilsProvider>
            {messages.length > 0 && (
                <SaleTable
                    headers={[
                        text.REMOVE,
                        'treść',
                        'odbiorcy',
                        'autor',
                        'data ważności',
                    ]}
                    rowRender={(row) => (
                        <TableRow
                            hover
                            key={row.message_id}
                            className={clsx(
                                moment(row.end_date).isBefore(moment()) &&
                                    classes.expired
                            )}
                        >
                            <TableCell align="center">
                                <IconButton
                                    onClick={() =>
                                        handleClickRemoveMessage(row.message_id)
                                    }
                                >
                                    <DeleteOutlineIcon color="error" />
                                </IconButton>
                            </TableCell>
                            <TableCell
                                className={classes.contentCell}
                                align="center"
                            >
                                {row.content}
                            </TableCell>
                            <TableCell align="center">
                                {row.is_saw.map((who) => (
                                    <CustomChip
                                        key={`saw-${row.message_id}-${who.name}`}
                                        className={classes.chip}
                                        size="small"
                                        variant="outlined"
                                        label={who.name}
                                        icon={<VisibilityIcon />}
                                        bgcolor={who.color}
                                    />
                                ))}
                                {row.is_not_saw.map((who) => (
                                    <CustomChip
                                        key={`notsaw-${row.message_id}-${who.name}`}
                                        className={classes.chip}
                                        size="small"
                                        variant="outlined"
                                        label={who.name}
                                        icon={<VisibilityOffIcon />}
                                        bgcolor={who.color}
                                    />
                                ))}
                            </TableCell>
                            <TableCell align="center">
                                {row.author_name}
                            </TableCell>
                            <TableCell align="center">
                                {row.end_date !== null
                                    ? moment(row.end_date).format(
                                          'YYYY-MM-DD HH:mm'
                                      )
                                    : '-'}
                            </TableCell>
                        </TableRow>
                    )}
                    rowsData={messages}
                />
            )}
        </ViewContentCard>
    );
};

export default Messages;
