// @flow
/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';

export const saleSlice = createSlice({
    name: 'sale',
    initialState: {
        search: {
            code: '',
            brand: '',
            color: '',
            type: '',
            shoperVariantId: '',
        },
    },
    reducers: {
        setSearchParam: (state, action) => {
            const { type, value } = action.payload;
            state.search[type] = value;
        },
        clearSearchParams: (state) => {
            state.search.code = '';
            state.search.brand = '';
            state.search.color = '';
            state.search.type = '';
            state.search.shoperVariantId = '';
        },
    },
});

export const selectSaleSearch = createSelector(
    (state) => state.sale.search,
    (sale) => sale
);

export const { clearSearchParams, setSearchParam } = saleSlice.actions;

export default saleSlice.reducer;
