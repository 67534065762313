// @flow
import React, { useState, useContext } from 'react';
import {
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import * as text from 'constants/strings';
import { 
    addAlert,
    selectCurrencyRate
} from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from 'components/SearchBar';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import SaleTableRow from 'components/SaleTableRow';
import { AddingInvoiceContext } from 'pages/Invoice';
import { validateNumberValue, convertToCurrency } from 'helpers/global';
import { clearSearchParams } from 'context/features/sale/saleSlice';
import { currencyCode } from 'constants/global';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingBottom: theme.spacing(2),
    },
    dialogTitle: {
        paddingBottom: 0,
    },
}));

const CustomerDialog = () => {
    const {
        invoiceDialogOpen,
        setInvoiceDialogOpen,
        setOrders,
        invoiceData
    } = useContext(
        AddingInvoiceContext
    );
    const dispatch = useDispatch();
    const classes = useStyles();
    const [result, setResult] = useState([]);
    const currencyRate = useSelector((state) => selectCurrencyRate(state));

    const handleClose = () => {
        setInvoiceDialogOpen(false);
    };

    const handleClickRow = (data, productData) => {
        if (!validateNumberValue(data.size)) {
            dispatch(
                addAlert({ content: 'Wybierz poprawny rozmiar', type: 'error' })
            );
            return;
        }
        const uniqueId = `${productData.id}-${Date.now()}`;
        const price = data.promotional_price > 0 ? data.promotional_price : data.primary_price;
        const currencyPrice = convertToCurrency(price, currencyRate[invoiceData.currency], true);

        setOrders((prev) =>
            prev.concat({
                ...productData,
                productId: productData.id,
                id: uniqueId,
                size: data.size,
                shopFromId: data.shopFromId,
                primary_price: data.primary_price,
                promotional_price: data.promotional_price,
                currency_price: currencyPrice,
            })
        );
    };

    const clearInput = () => {
        dispatch(clearSearchParams());
        setResult([]);
    }

    const invoiceAction = (data, rowData) => {
        handleClickRow(data, rowData);
        clearInput();
    }

    return (
        <Dialog
            className={classes.dialogTable}
            maxWidth={false}
            open={invoiceDialogOpen}
            onClose={handleClose}
            style={{ maxHeight: '90vh' }}
        >
            <DialogTitle className={classes.dialogTitle}>
                Dodawanie nowego produktu
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <SearchBar setResult={setResult} />
                {result.length > 0 && (
                    <SaleTableWithPagination
                        size="small"
                        headers={[
                            text.CODE,
                            text.BRAND,
                            text.COLOR,
                            text.MODEL,
                            text.NORMAL_PRICE,
                            text.PROMOTIONAL_PRICE,
                            text.DISCOUNT,
                            text.COUPON,
                            text.SIZE,
                            '',
                        ]}
                        rowRender={(row) => (
                            <SaleTableRow
                                currencyVisible={false}
                                key={row.id}
                                rowData={row}
                                handleClickRow={invoiceAction}
                                showCouponSelect
                            />
                        )}
                        rowsData={result}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default CustomerDialog;
