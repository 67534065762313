// @flow
import React, { useMemo, useState } from 'react';
import * as text from 'constants/strings';
import ViewContentCard from 'components/Layout/ViewContentCard';
import { sendAuthAjax } from 'helpers/authorization';
import { useDispatch } from 'react-redux';
import { addAlert, setIsLoading } from 'context/features/global/globalSlice';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
    Button,
    FormControlLabel,
    Grid,
    makeStyles,
    Switch,
    TableCell,
    TableRow,
    TextField,
    Paper,
    TableContainer,
    TableBody,
    Table,
    TableHead,
    FormControl,
    Select,
    MenuItem,
} from '@material-ui/core';
import { StyledTableCell } from 'styles/components';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import SaleTable from 'components/SaleTable';
import { statementSearchMode } from 'constants/global';

const useStyles = makeStyles({
    tableWithDetail: {
        '& thead th, & tbody td': {
            padding: '6px',
            textAlign: 'center',
        },
    },
    highlightedValue: {
        fontWeight: 800,
    },
    emptyValue: {
        color: 'red',
        opacity: 0.3,
    },
    row: {
        '&:hover': { cursor: 'pointer' },
    },
    searchTypeSelect: {
        width: '100%',
    }
});

const StatementTotal = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useState({
        date: new Date(),
        fromDate: new Date(),
        toDate: new Date(),
        code: '',
        brand: '',
    });
    const [expandedDetail, setExpandedDetail] = useState(false);
    const [result, setResult] = useState([]);
    const classes = useStyles();
    const history = useHistory();
    const [searchMode, setSearchMode] = useState(statementSearchMode.month);

    const getStatementTotal = async () => {
        let dateFrom = null;
        let dateTo = null;

        if (searchMode === statementSearchMode.month) {
            if (!moment(searchParams.date).isValid()) {
                return;
            }

            dateFrom = moment(searchParams.date)
                .startOf('month')
                .format('YYYY-MM-DD');
            dateTo = moment(searchParams.date)
                .endOf('month')
                .format('YYYY-MM-DD');
        } else if (searchMode === statementSearchMode.period) {
            if (!moment(searchParams.fromDate).isValid() || !moment(searchParams.toDate).isValid()) {
                return;
            }

            dateFrom = moment(searchParams.fromDate)
                .format('YYYY-MM-DD');
            dateTo = moment(searchParams.toDate)
                .format('YYYY-MM-DD');
        }

        if (!dateFrom || !dateTo) {
            return;
        }

        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/statement/total?dateFrom=${dateFrom}&dateTo=${dateTo}&code=${searchParams.code}&brand=${searchParams.brand}`
            );
            setResult(response.data);
            if (!response.data[0]?.days) {
                setExpandedDetail(false);
            }
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickSearch = (e) => {
        e.preventDefault();
        getStatementTotal();
    };

    const handleSubmitEnter = (e) => {
        if (e.key === 'Enter') {
            getStatementTotal();
        }
    };

    const handleClickRow = (rowId: number) => {
        history.push(`/historia-poszczegolnego-modelu/${rowId}`);
    };

    const getIndex = (row) => {
        return result.findIndex((item) => item.productId === row.productId) + 1
    }

    const getCurrentPeriod = () => {
        let start = null;
        let end = null;
        if (searchMode === statementSearchMode.month) {
            start = moment(searchParams.date).startOf('month');
            end = moment(searchParams.date).endOf('month');
        } else if (searchMode === statementSearchMode.period) {
            start = moment(searchParams.fromDate);
            end = moment(searchParams.toDate);
        }
        
        const days = [];
        let day = start;

        while (day <= end) {
            days.push(day.format('D'));
            day = day.clone().add(1, 'd');
        }

        return days;
    };

    const getDayCount = (day, daysData) => {
        return daysData[day] ? (
            <span className={classes.highlightedValue}>{daysData[day]}</span>
        ) : (
            <span className={classes.emptyValue}>0</span>
        );
    };

    const headers = 
        expandedDetail && result[0]?.days
            ? [
                  '',
                  text.CODE,
                  text.BRAND,
                  text.COLOR,
                  text.SUM,
                  ...getCurrentPeriod(),
              ]
            : ['', text.CODE, text.BRAND, text.COLOR, text.SUM];
    

    const subtotal = (items) => {
        return items.map(({ sum }) => sum).reduce((sum, i) => sum + i, 0);
    }

    const invoiceSubtotal = subtotal(result);

    const tableRender = (rows) => {
        return (
            <Grid item xs={12}>
                {rows.length > 0 && 
                    <div className={classes.root}>
                        <TableContainer
                            component={Paper}
                            className={classes.container}
                        >
                            <Table
                                stickyHeader
                                size="small"
                                className={clsx(
                                    expandedDetail && classes.tableWithDetail
                                )}
                            >
                                <TableHead>
                                    <TableRow>
                                        {headers.map((head) => (
                                            <StyledTableCell key={`head-${head}`}>
                                                {head}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {result.map((row) => (
                                        <TableRow
                                            className={classes.row}
                                            key={row.productId}
                                            onClick={() =>
                                                handleClickRow(row.productId)
                                            }
                                            hover
                                        >
                                            <TableCell align="center">
                                                {getIndex(row)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row.productCode}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row.productBrand}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row.productColor}
                                            </TableCell>
                                            <TableCell
                                                className={classes.highlightedValue}
                                                align="center"
                                            >
                                                {row.sum}
                                            </TableCell>
                                            {expandedDetail && rows[0]?.days &&
                                                getCurrentPeriod().map((day) => (
                                                    <TableCell
                                                        key={`${row.productId}-${day}`}
                                                    >
                                                        {getDayCount(day, row.days)}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    ))}
                                        <TableRow>
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                            <TableCell align="right">Łącznie</TableCell>
                                            <TableCell align="center">{invoiceSubtotal}</TableCell>
                                        </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
            </Grid>
        )
    }

    const table = useMemo(() => tableRender(result), [result, expandedDetail]);

    return (
        <ViewContentCard title={text.STATEMENT_TOTAL}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} md={2} lg={1}>
                        <FormControl className={classes.searchTypeSelect}>
                            <Select
                                value={searchMode}
                                onChange={(e) => setSearchMode(e.target.value)}
                            >
                                <MenuItem value={statementSearchMode.month}>
                                    {statementSearchMode.month}
                                </MenuItem>
                                <MenuItem value={statementSearchMode.period}>
                                    {statementSearchMode.period}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {searchMode === statementSearchMode.month && (
                        <Grid item xs={12} md={10} lg={4}>
                            <KeyboardDatePicker
                                fullWidth
                                label="Data"
                                size="small"
                                views={['year', 'month']}
                                variant="inline"
                                inputVariant="outlined"
                                value={searchParams.date}
                                onChange={(date) =>
                                    setSearchParams({ ...searchParams, date })
                                }
                            />
                        </Grid>
                    )}
                    {searchMode === statementSearchMode.period && (
                        <>
                            <Grid item xs={12} md={5} lg={2}>
                                <KeyboardDatePicker
                                    autoOk
                                    fullWidth
                                    label="Od"
                                    size="small"
                                    format="YYYY-MM-DD"
                                    variant="inline"
                                    inputVariant="outlined"
                                    value={searchParams.fromDate}
                                    onChange={(fromDate) => setSearchParams({ ...searchParams, fromDate })}
                                />
                            </Grid>
                            <Grid item xs={12} md={5} lg={2}>
                                <KeyboardDatePicker
                                    autoOk
                                    fullWidth
                                    label="Do"
                                    size="small"
                                    format="YYYY-MM-DD"
                                    variant="inline"
                                    inputVariant="outlined"
                                    value={searchParams.toDate}
                                    minDate={searchParams.fromDate}
                                    onChange={(toDate) => setSearchParams({ ...searchParams, toDate })}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} md={6} lg={3}>
                        <TextField
                            fullWidth
                            size="small"
                            value={searchParams.code}
                            onChange={(e) =>
                                setSearchParams({
                                    ...searchParams,
                                    code: e.target.value,
                                })
                            }
                            label="kod"
                            variant="outlined"
                            onKeyDown={handleSubmitEnter}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextField
                            fullWidth
                            size="small"
                            value={searchParams.brand}
                            onChange={(e) =>
                                setSearchParams({
                                    ...searchParams,
                                    brand: e.target.value,
                                })
                            }
                            label="marka"
                            variant="outlined" 
                            onKeyDown={handleSubmitEnter}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={1}>
                        <Button
                            fullWidth
                            onClick={handleClickSearch}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            szukaj
                        </Button>
                    </Grid>
                    {result[0]?.days && (
                        <Grid item xs={12} md={6} lg={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={expandedDetail}
                                        onChange={(e) =>
                                            setExpandedDetail(e.target.checked)
                                        }
                                    />
                                }
                                label="rozwiń szczegóły"
                            />
                        </Grid>
                    )}
                    {table}
                </Grid>
            </MuiPickersUtilsProvider>
        </ViewContentCard>
    );
};

export default StatementTotal;
