import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

function TableLoader() {
    return (
        <div
            style={{
                overflow: 'hidden',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pointerEvents: 'painted',
                background: 'rgba(255, 255, 255, 0.8)',
                zIndex: 22,
            }}
        >
            <CircularProgress size="2rem" />
        </div>
    );
}

export default TableLoader;
