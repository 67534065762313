import { AxiosError } from 'axios';
import { sendAuthAjax } from 'helpers/authorization';
import { AttendanceDto, ChecklistReport, ChecklistReportUpdateDto } from 'helpers/interface';
import { useMutation, UseMutationOptions } from 'react-query';

const path = '/checklist-reports/';

const putChecklistReport = async (payload: ChecklistReportUpdateDto) => {
    const { data } = await sendAuthAjax('put', path + payload.id.toString(), payload);
    return data;
};

export default function useUpdateChecklistReportMutation(
    options: UseMutationOptions<ChecklistReport, AxiosError, ChecklistReportUpdateDto> = {}
) {
    return useMutation<ChecklistReport, AxiosError, ChecklistReportUpdateDto>(
        putChecklistReport,
        options
    );
}
