// @flow
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import { sendAuthAjax } from 'helpers/authorization';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Button,
    Divider,
    makeStyles,
} from '@material-ui/core';
import * as text from 'constants/strings';
import ViewContentCard from '../components/Layout/ViewContentCard';

moment.locale('pl');

const useStyles = makeStyles((theme) => ({
    createSummaryDivider: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    thresholdButton: {
        marginRight: '20px',
        marginTop: '10px',
    },
    nameCell: {
        width: '20%',
        [theme.breakpoints.down('sm')]: {
            width: '30%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '50%',
        }
    },
    thresholdInput: {
        width: '100%',
    }
}));

const GraphicThreshold = () => {
    const dispatch = useDispatch();
    const [employeesData, setEmployeesData] = useState([]);
    const [employeesThresholdData, setEmployeesThresholdData] = useState({});
    const classes = useStyles();
    
    const getEmployeesData = async () => {
        const url = '/threshold';
        let initialEmployeesIds = {}

        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthAjax('get', url);
            setEmployeesData(response.data);
            response.data.forEach(element => {
                initialEmployeesIds = {
                    ...initialEmployeesIds,
                    [element.employeeId]: element.threshold ? element.threshold : '',
                };
            });

            setEmployeesThresholdData(initialEmployeesIds);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickSave = async () => {
        dispatch(setIsLoading(true));

        try {
            let employeesThreshold = JSON.stringify(employeesThresholdData);
            await sendAuthAjax('post', "/threshold", employeesThreshold);

            dispatch(
                addAlert({ content: 'Poprawnie zapisano', type: 'success' })
            );
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getEmployeesData();
    }, []);

    return (
        <ViewContentCard title={text.THRESHOLD}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleClickSave()}
                className={classes.thresholdButton}
            >
                {text.SET_THRESHOLDS}
            </Button>
            <Divider
                className={classes.createSummaryDivider}
            />
     
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableBody>
                        {employeesData.map((row) => 
                            <TableRow key={row.employeeId}>
                                <TableCell className={classes.nameCell}>
                                    <TextField
                                        type="text"
                                        disabled
                                        value={row.firstName + ' ' + row.lastName}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        label="PRÓG"
                                        type="text"
                                        value={employeesThresholdData[row.employeeId]}
                                        className={classes.thresholdInput}
                                        onChange={(e) =>
                                            {   
                                                setEmployeesThresholdData({ ...employeesThresholdData, [row.employeeId]: e.target.value });
                                            }
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </ViewContentCard>
    );
};

export default GraphicThreshold;