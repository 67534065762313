// @flow
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuthorization from 'hooks/useAuthorization';
import Base from 'components/Layout/Base';
import { useSelector } from 'react-redux';
import { selectUser } from 'context/features/user/userSlice';
import Alert from '@material-ui/lab/Alert';

const PrivateRoute = ({ children, ...props }: any) => {
    const isAuthenticated = useAuthorization();
    const { shopRequired, visible } = props;
    const user = useSelector((state) => selectUser(state));

    if (isAuthenticated === null) {
        return <div />;
    }

    if (shopRequired && user.shopId === null) {
        return (
            <Alert
                variant="outlined"
                severity="warning"
                style={{ border: 'none' }}
            >
                Brak przypisanego sklepu
            </Alert>
        );
    }

    if (!visible) {
        return <Route {...props} render={() => <Redirect to="/" />} />;
    }

    return (
        <Route
            {...props}
            render={() =>
                isAuthenticated ? <Base>{children}</Base> : <Redirect to="/" />
            }
        />
    );
};

export default PrivateRoute;
