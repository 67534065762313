// @flow
import React, { useState, useEffect } from 'react';
import {
    Typography,
    TableCell,
    TableRow,
    IconButton,
    Grid,
    makeStyles,
} from '@material-ui/core';
import * as text from 'constants/strings';
import 'moment/locale/pl';
import moment from 'moment';
import SaleTable from 'components/SaleTable';
import { sendAuthAjax } from 'helpers/authorization';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { currencySymbol, currencyCode } from 'constants/global';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {
    formatNumber,
    findColorShopByName,
    findColorByShopNameKrupowki,
} from 'helpers/global';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import useIsGranted from 'hooks/useIsGranted';
import { reduce } from 'lodash';

const useStyles = makeStyles((theme) => ({
    h6: {
        paddingBottom: '.8rem',
        borderBottom: '1px solid #ccc',
        marginBottom: '.8rem',
        marginTop: '1rem',
        textAlign: 'center',
    },
    row: {
        '&:hover': { cursor: 'pointer' },
    },
    productOfTheDayIcon: {
        backgroundColor: 'green',
        color: 'white',
        fontSize: '0.8rem',
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        margin: '1px',
        lineHeight: '20px',
    },
}));

type Props = {
    date: any,
    shopId: number,
    hasPermissions: boolean,
    shops: array,
    title: string,
};

const CustomTypography = styled(Typography)`
    color: ${({ fontcolor }) => fontcolor && fontcolor};
`;

const OrdersPreviewTable = ({
    date,
    shopId,
    hasPermissions,
    shops = [],
    title = '',
}: Props) => {
    const [result, setResult] = useState([]);
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const isGranted = useIsGranted();

    const isManager = isGranted.hasOneOfRoles.execute([
        text.ROLE_ADMIN_CODE,
        text.ROLE_MANAGER_CODE,
    ]);
    const canDeleteInPreview = isGranted.hasOneOfRoles.execute([
        text.ROLE_EMPLOYEE_CODE,
    ]);
    const getOrdersPreview = async () => {
        if (!moment(date).isValid() || shopId === '') {
            setResult([]);
            return;
        }

        dispatch(setIsLoading(true));

        const url = `/orders/preview?shopId=${shopId}&date=${moment(
            date
        ).format('YYYY-MM-DD')}`;
        try {
            const response = await sendAuthAjax('get', url);

            setResult(response.data);
        } catch (error) {
            setResult([]);
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getOrdersPreview();
    }, [date, shopId]);

    const handleOnClick = async (id) => {
        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax('delete', `/orders/${id}`);

            setResult(result.filter((item) => item.id !== id));
            dispatch(
                addAlert({ content: 'Poprawnie usunięto', type: 'success' })
            );
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickRow = (rowId: number) => {
        history.push(`/historia-poszczegolnego-modelu/${rowId}`);
    };

    const getSummeryInnerText = (type, summary) => {
        let t = '';
        const { count, price, euro, dollar, pound, crown, currency } = summary;
        let value = `${price}${currencySymbol.PLN}`;

        Object.keys(currency).map(key => {
            value += ` + ${currency[key]}${currencySymbol[key]}`;
        })

        switch (type) {
            case 'Wkładki':
                t = `\n${count} par wkładek o łącznej wartości ${value}`;
                break;
            case 'Skarpety':
                t = `\n${count} par skarpet o łącznej wartości ${value}`;
                break;
            default:
                t = `\n${count} par obuwia o łącznej wartości ${value}`;
                break;
        }

        return count > 0 ? t : '';
    };

    const getSummary = () => {
        const summary = reduce(
            result,
            (p, c) => {
                const { 
                    primaryPrice,
                    promotionalPrice,
                    brand,
                    currency,
                    currencyPrice
                } = c;
                const summaryPerProduct = p;

                let price =
                    promotionalPrice > 0
                        ? parseFloat(promotionalPrice)
                        : parseFloat(primaryPrice);

                let currencyPriceValue = 0;

                if (currency !== currencyCode.PLN) {
                    price = 0;
                    currencyPriceValue = parseInt(currencyPrice, 10);
                }

                switch (brand) {
                    case 'Skarpety':
                        summaryPerProduct.socks.count += 1;
                        summaryPerProduct.socks.price += price;

                        if (currency !== currencyCode.PLN) {
                            summaryPerProduct.socks.currency[currency] = 
                                summaryPerProduct.socks.currency[currency]
                                ? summaryPerProduct.socks.currency[currency] + currencyPriceValue
                                : currencyPriceValue;
                        }
                        break;
                    case 'Wkładki':
                        summaryPerProduct.insoles.count += 1;
                        summaryPerProduct.insoles.price += price;

                        if (currency !== currencyCode.PLN) {
                            summaryPerProduct.insoles.currency[currency] = 
                                summaryPerProduct.insoles.currency[currency] && currency !== currencyCode.PLN
                                ? summaryPerProduct.insoles.currency[currency] + currencyPriceValue
                                : currencyPriceValue;
                        }
                        break;
                    default:
                        summaryPerProduct.shoes.count += 1;
                        summaryPerProduct.shoes.price += price;

                        if (currency !== currencyCode.PLN) {
                            summaryPerProduct.shoes.currency[currency] =
                                summaryPerProduct.shoes.currency[currency] && currency !== currencyCode.PLN
                                ? summaryPerProduct.shoes.currency[currency] + currencyPriceValue
                                : currencyPriceValue;
                        }
                        break;
                }

                return summaryPerProduct;
            },
            {
                socks: { count: 0, price: 0, currency: {} },
                insoles: { count: 0, price: 0, currency: {} },
                shoes: { count: 0, price: 0, currency: {} },
            }
        );

        const summaryShoesText = getSummeryInnerText('shoes', summary.shoes);
        const summarySocksText = getSummeryInnerText('Skarpety', summary.socks);
        const summaryInsolesText = getSummeryInnerText(
            'Wkładki',
            summary.insoles
        );

        return `Sprzedano: ${summaryShoesText} ${summarySocksText} ${summaryInsolesText}`;
    };

    const isShowIcon = (dt) => {
        if (dt !== null) {
            const createAt = moment(dt);
            const day = moment().subtract(14, 'd');

            return createAt.isAfter(day);
        }
    };

    const isShowIconNew = (dt) => {
        const createAt = moment(dt);
        const day = moment().subtract(7, 'd');
        return createAt.isAfter(day);
    };

    const getPrice = (type, row) => {
        const price =
            row.currency 
            && row.currency
            !== currencyCode.PLN
            ? row.currencyPrice
            : row[type];
        const currency = 
            row.currency 
            !== currencySymbol.PLN
            ? currencySymbol[row.currency]
            : currencySymbol.PLN;
                
        return `${formatNumber(price)}${currency}`;
    };

    return (
        <>
            {result.length > 0 && (
                <>
                    {title && (
                        <CustomTypography
                            variant="h6"
                            className={classes.h6}
                            fontcolor={findColorByShopNameKrupowki(
                                shops,
                                shopId
                            )}
                        >
                            {title}
                        </CustomTypography>
                    )}

                    <Typography
                        variant="subtitle2"
                        align="center"
                        style={{
                            whiteSpace: 'break-spaces',
                            marginBottom: '10px',
                        }}
                    >
                        {isGranted.hasOneOfRoles.execute([
                            text.ROLE_MANAGER_CODE,
                            text.ROLE_ADMIN_CODE,
                        ]) && getSummary()}
                    </Typography>
                </>
            )}
            {result.length > 0 && (
                <SaleTable
                    headers={[
                        canDeleteInPreview ? text.REMOVE : null,
                        text.CODE,
                        text.BRAND,
                        text.COLOR,
                        text.MODEL,
                        text.NORMAL_PRICE,
                        text.PROMOTIONAL_PRICE,
                        text.SIZE,
                        text.COUPON,
                        text.FROM_SHOP,
                        text.HOUR,
                    ].filter((head) => head)}
                    rowRender={(row) => (
                        <TableRow
                            hover
                            key={row.id}
                            className={isManager ? classes.row : ''}
                            onClick={
                                isManager
                                    ? () => handleClickRow(row.productId)
                                    : null
                            }
                        >
                            {canDeleteInPreview && (
                                <TableCell align="center">
                                    <IconButton
                                        onClick={() => handleOnClick(row.id)}
                                    >
                                        <DeleteOutlineIcon color="error" />
                                    </IconButton>
                                </TableCell>
                            )}
                            <TableCell align="center">
                                <Grid
                                    container
                                    alignItems="center"
                                    wrap="nowrap"
                                    justify="center"
                                >
                                    {isShowIconNew(row.productCreatedAt) && (
                                        <NewReleasesIcon color="primary" />
                                    )}
                                    {isShowIcon(
                                        row.productChangedPriceDate
                                    ) && (
                                        <MonetizationOnIcon color="secondary" />
                                    )}
                                    {row.isProductOfTheDay && (
                                        <div
                                            className={
                                                classes.productOfTheDayIcon
                                            }
                                        >
                                            PD
                                        </div>
                                    )}
                                    {row.code}
                                </Grid>
                            </TableCell>
                            <TableCell align="center">{row.brand}</TableCell>
                            <TableCell align="center">{row.color}</TableCell>
                            <TableCell align="center">{row.type}</TableCell>
                            <TableCell align="center">
                                {getPrice('primaryPrice', row)}
                            </TableCell>
                            <TableCell align="center">
                                {getPrice('promotionalPrice', row)}
                            </TableCell>
                            <TableCell align="center">{row.size}</TableCell>
                            <TableCell align="center">
                                {row?.CouponCode || '-'}
                            </TableCell>
                            <TableCell
                                align="center"
                                bgcolor={findColorShopByName(
                                    shops,
                                    row.fromShop
                                )}
                            >
                                {row.fromShop}
                            </TableCell>
                            <TableCell
                                align="center"
                                bgcolor={findColorShopByName(
                                    shops,
                                    row.fromShop
                                )}
                            >
                                {moment(row.createdAt).format('HH:mm:ss')}
                            </TableCell>
                        </TableRow>
                    )}
                    rowsData={result}
                />
            )}
        </>
    );
};

export default OrdersPreviewTable;
