// @flow
import React, { useEffect, useState } from 'react';
import {
    Button,
    IconButton,
    Typography,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    makeStyles,
    Grid,
} from '@material-ui/core';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import { sendAuthAjax } from 'helpers/authorization';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { StyledTableCell } from 'styles/components';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    table: {
        width: "max-content",
        minWidth: 250,
        margin: "2rem auto",

        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            minWidth: 250,
            margin: "2rem auto",
        },
    },
    tableCell: {
        padding: "6px 10px 6px 10px"
    },
    countText: {
        width: 15,
    },
    grid: {
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center"
    },
    header: {
        textAlign: "center"
    }
}));

type Props = {
    shopId: number,
    warehouseId: number,
    productId: number,
    setWarehouseUpdate: Function,
    warehouseUpdate: number,
};

const Switcher = ({
    value,
    increment,
    decrement,
}: {
    value: number,
    increment: Function,
    decrement: Function,
}) => {
    const classes = useStyles();

    return (
        <Grid container justify="center" className={classes.grid}>
            <IconButton onClick={() => increment(value)}>
                <AddCircleOutlineIcon color="primary" />
            </IconButton>
            <Typography className={classes.countText} variant="h6">
                {value}
            </Typography>
            <IconButton onClick={() => decrement(value)}>
                <RemoveCircleOutlineIcon color="secondary" />
            </IconButton>
        </Grid>
    );
};

const AddInventoryBlock = ({
    setWarehouseUpdate,
    warehouseUpdate,
    shopId,
    warehouseId,
    productId,
}: Props) => {
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const classes = useStyles();

    const getInventories = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/shops/${warehouseId}/${productId}/inventories`
            );
            const state = response.data.reduce((p, c) => {
                return p.concat({ ...c, topUp: 0 });
            }, []);
            setResult(state);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickTopUp = async () => {
        dispatch(setIsLoading(true));

        const sizes = result.reduce(
            (p, c) => p.concat({ quantity: c.topUp, size: c.size }),
            []
        );

        const payload = {
            productId,
            sizes,
        };

        try {
            await sendAuthAjax(
                'put',
                `/addition/${warehouseId}/${shopId}`,
                payload
            );
            setWarehouseUpdate(Date.now());
            dispatch(addAlert({ content: 'Dołożono', type: 'success' }));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const decrement = (size, value, threshold) => {
        setResult(
            result.map((item) => {
                if (item.size === size) {
                    return {
                        ...item,
                        topUp: value <= threshold ? value : value - 1,
                    };
                }

                return item;
            })
        );
    };

    const increment = (size, value, threshold) => {
        setResult(
            result.map((item) => {
                if (item.size === size) {
                    return {
                        ...item,
                        topUp: value >= threshold ? value : value + 1,
                    };
                }

                return item;
            })
        );
    };

    useEffect(() => {
        getInventories();
    }, [warehouseUpdate, warehouseId, productId]);

    return (
        result.length > 0 && (
            <TableContainer className={classes.table} component={Paper}>
                <Typography variant="h6" className={classes.header}>
                    Dołożenie
                </Typography>
                <Table size="small">
                    <TableBody>
                        <TableRow key="invertoryBlockHeader">
                            {result.map((row) => (
                                <StyledTableCell align="center" key={row.size} className={classes.tableCell}>
                                    {row.size}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                        <TableRow key="invertoryBlockBody">
                            {result.map((row) => (
                                <TableCell align="center" key={`${row.size}-${row.value}`} className={classes.tableCell}>
                                    <Switcher
                                        value={
                                            result.find(
                                                (item) => item.size === row.size
                                            ).topUp
                                        }
                                        decrement={(value) =>
                                            decrement(row.size, value, 0)
                                        }
                                        increment={(value) =>
                                            increment(
                                                row.size,
                                                value,
                                                row.quantity
                                            )
                                        }
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
                <Button
                    fullWidth
                    onClick={handleClickTopUp}
                    variant="contained"
                    color="primary"
                >
                    Dołóż
                </Button>
            </TableContainer>
        )
    );
};

export default AddInventoryBlock;
